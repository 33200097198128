/* ==========================================================================
   Open Streetmap static image
   ========================================================================== */

/**
 * c-map
 * 
 */

.c-map {
    background-color: $white;
    padding: $paragraph-margin-bottom;
    box-shadow: $global-shadow;
    margin-bottom: $paragraph-margin-bottom;

    &__image {
        width: 100%;
        height: auto;
    }
}


