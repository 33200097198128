/* ==========================================================================
   icon-wallpaper
   ========================================================================== */

/**
 * base styles
 */

.c-icon-wallpaper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;

    /*@include breakpoint(large) {
        //flex: 0 1 48.5%;
    }*/ //todo mn

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        //width: 100%; //todo mn
        max-width: 200px;
        height: 100%;
        padding: rem(20) rem(30);

        /*@include breakpoint(large) {
            //flex: 0 1 48.5%;
        }*/ //todo mn

    }

    &__image {
        filter: grayscale(1);
        transition: filter .3s ease-in-out;
        width: auto;

        &:hover {
            filter: grayscale(0);
        }

        &--ls {
            max-height: rem(110); // landscape image
        }
        &--pt {
            max-height: rem(130); // portrait image
        }

    }


}