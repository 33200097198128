/* ==========================================================================
   Mobile Navigation
   ========================================================================== */

/**
 * Button
 */


.c-mobile-button {
    padding: rem(8 8);
    background-color: $primary-color;
    display: flex;
    align-items: center;
    border-radius: $global-radius;
}

.c-mobile-button__items {
    flex: 0 0 auto;
    width: rem(20);
    height: rem(20);
    transform: rotate(0deg);
    transition: transform .5s ease-in-out;
    cursor: pointer;
    position: relative;

    &:focus{
      outline: none;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $white;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2), &:nth-child(3) {
        top: rem(8);
      }

      &:nth-child(4) {
        top: rem(16);
      }
    }
    // click actions

    &.is-open {

      span:nth-child(1), span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      span:nth-child(2) {
        transform: rotate(45deg);
      }

      span:nth-child(3) {
        transform: rotate(-45deg);
      }

    }

    @include breakpoint(large) {
      @include visually-hidden;
    }

}


/**
 * Navigation
 */

 .c-mobile-nav {
    display: block;
    max-height: 0;
    transition: max-height .5s ease-out;
    overflow: hidden;
    //position: relative;
    padding-top: rem(62);
    //z-index: 2;

    @include breakpoint(medium) {
      top: rem(96);
    }

    &.is-open {
      max-height: rem(1000);
      transition: max-height .9s ease-in;
    }

    &__list {
      list-style-type: none;
      margin: 0;

    }

    &__link {
        background-color: $primary-color;
        border-bottom: 3px solid $white;
        color: $white;
        fill: $white;
        display: block;
        padding: rem(20 25);
        font-weight: 700;

      &.is-parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

    }

    &__link-text {
        position: relative;
        flex: 0 0 auto; // needed for IE
    }

    &__icon {
        fill: $white;
        width: rem(20);
        height: rem(20);
        transform: rotate(90deg);
        flex: 0 0 auto;
    }

    @include breakpoint(large) {
        display: none;
    }

    // note: some bad overriding happening here
    .c-nav__loginbutton {
        background: get-color(secondary);
    }

    .c-nav__loginicon {
        float: left;
        margin-left: 0;
        margin-right: rem(7);
    }
 }

 /**
 * Sub Navigation
 */

 .c-mobile-sub-nav {
      list-style-type: none;
      margin: 0;
      padding: rem(10 0 10 20);
      border-bottom: 3px solid $white;
      background: lighten(get-color(primary), 6%);

      &__link {
        color: $white;
        display: block;
        padding: rem(7 25);
      }

 }
