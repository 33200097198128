/* ==========================================================================
   Event Results Listings
   ========================================================================== */

.c-jobblock {

    padding: rem(10) rem(8) 0;
    margin: $global-margin rem(- map-get($grid-column-gutter, large)*0.5); // removes padding left and right in grid to give our item 100% browser width

    @include breakpoint(small only) {
        .grid-padding-x > .cell {
            padding-left: rem(8);
            padding-right: rem(8);
        }
    }

    @include breakpoint(medium) {
        padding: rem(20) 0 0;
    }

    @include breakpoint(large) {
        //margin-left: 0;
        //margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }


    &__headline {
        line-height: rem(23);
        font-size: $paragraph-font-size;
        margin-top: rem(8);
        word-break: break-word;

        @include breakpoint(medium) {
            margin-top: 0;
            font-size: $paragraph-font-size * 1.125;
            word-break: normal;
        }
    }

    &__details {
        font-size: $small-font-size;
        color: $dark-gray;
        font-family: $header-font-family;
        display: block;
        margin-bottom: rem(3);
        font-weight: 700;
    }

    &__icon {
        width: rem(15);
        height: rem(15);
        fill: $dark-gray;
        top: rem(2);
        position: relative;
        margin-right: rem(5);
    }

    &__meta {
        font-size: $small-font-size;
        color: $dark-gray;
        display: block;
    }

    // Premium Settings
    &--topjob {

        background-color: rgba($lightest-gray, 0.5);

        .c-jobblock__headline {
            font-size: $lead-font-size;

            @include breakpoint(medium) {
                font-size: $lead-font-size-medium;
                line-height: rem(26);
            }
        }
    }
}


[data-joblistresults] {
    transition: height 2s ease-in-out;

    .c-jobblock {
        animation: animateElement 0.5s ease both;
        animation-iteration-count: 1;

        @for $i from 1 through 10 {
            &:nth-of-type(10n+#{$i}) {
              animation-delay: #{$i * 0.2}s;
            }
          }
    }
}

@keyframes animateElement{
    0% {
      opacity:0;
      transform:  translate(0px,10px);
    }
    100% {
      opacity:1;
      transform:  translate(0px,0px);
    }
  }
