/* ==========================================================================
   Social Icons
   ========================================================================== */

/**
 * base styles - footer
 */

.c-social-icons {

    margin: rem(map-get($grid-padding-gutters, 'medium')) auto 0 auto;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium) {
        margin: 0;
    }

    &__link {
        padding: rem(10);
        display: block;
        &:focus {
            outline: none;
            .c-social-icons__icon {
                fill: $primary-color;
            }
        }
        &:hover {
            outline: none;
            .c-social-icons__icon {
                fill: $body-font-color;
            }
        }
    }

    &__icon {
        width: rem(30);
        height: rem(30);
        fill: $white;
        transition: fill .3s ease-in-out;
    }
}
