/* ==========================================================================
   Extended Header
   ========================================================================== */

/**
 * l-extended-header-content
 * styles used on company profile page
 */

 .l-extended-header-content {
    background-color: $white;
    position: relative;
    z-index: 3;
    max-width: rem(1100);
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(large) {
        transform: translateY(-200px);
        margin-bottom: rem(-200);
    }

 }
