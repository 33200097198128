/* ==========================================================================
   Filter for the jobs results page
   ========================================================================== */

/**
 * c-jobs-filter
 * Filters on the jobs page
 */

 $filter-box-shadow: rem(-1) rem(3) rem(5) rem(0) $medium-gray;

.c-jobs-filter {

    //********************************************** header section

    &__header {
        display: block;
        margin-bottom: rem(10);
    }

    @include breakpoint(small only) { // styles for toggle filter area on small screens only

        margin-left: rem(-15);
        margin-right: rem(-15);

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $white;
            padding: rem(5 8);
            box-shadow: $filter-box-shadow;
        }

    }

    @include breakpoint (medium) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    &__heading {
        display: flex;
        align-items: center;
    }

    &__heading-text {
        color: $dark-gray;
        display: inline-block;
        margin: rem(0 5);
        font-family: $header-font-family;
        font-size: $small-font-size;
    }

    &__icon {
        width: rem(25);
        height: rem(25);
        fill: $dark-gray;
    }

    &__toggle {
        // show toggle only on small screens

        @include breakpoint(medium) {
            display: none;
        }
    }

    &__toggle-icon {
        width: rem(16);
        height: rem(16);
        fill: $dark-gray;
        position: relative;
        top: rem(3);
        right: rem(6);
        transform: rotate(90deg);// flips the icon
    }

    //********************************************** Filter section


    &__filters {
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-in-out;

        @include breakpoint(medium) {
            display: flex;
            align-items: center;
            max-height: rem(2000);

        }
    }

    &__dropdowns {
        margin-bottom: rem(20);

        @include breakpoint(medium) {
            margin-right: rem(30);
            display: flex;
            margin-bottom: rem(10);
        }

    }

    & select, & select:focus {
        display: inline-block;
        background-color: $light-gray;
        font-size: rem(15);
        border: none;
        margin: 0;
        height: rem(50);
        background-size: rem(15) rem(15);
        background-position: right rem(-10) center;
        background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Capa_1" transform="matrix(2.24763e-16,0.793439,-0.793439,2.24763e-16,107.921,-35.6916)"><path d="M139.179,73C139.179,72.143 138.75,71.179 138.107,70.536L88.179,20.607C87.536,19.964 86.571,19.536 85.714,19.536C84.857,19.536 83.893,19.964 83.25,20.607L77.893,25.964C77.25,26.607 76.821,27.571 76.821,28.429C76.821,29.286 77.25,30.25 77.893,30.893L120,73L77.893,115.107C77.25,115.75 76.821,116.714 76.821,117.571C76.821,118.536 77.25,119.393 77.893,120.036L83.25,125.393C83.893,126.036 84.857,126.464 85.714,126.464C86.571,126.464 87.536,126.036 88.179,125.393L138.107,75.464C138.75,74.821 139.179,73.857 139.179,73Z" style="fill:#8a8a8a;fill-rule:nonzero;"/></g></svg>');
        cursor: pointer;
        max-width: 100%; // needed for Edge and IE
        padding-left: rem(5);

        & select:focus {
            border: none;
            background-color: $light-gray;
        }

        @include breakpoint(medium) {
            background-position: right rem(-17) center;
            margin: 0 rem(5) 0;
            flex: 0 1 auto;
            height: rem(40);
            //max-width: rem(124); // needed for Edge and IE
            max-width: 33%;
        }

    }

    &__premium-toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $small-font-size;
        margin-bottom: .625rem;

        span {
            margin-right: rem(5);
        }

        @include breakpoint(medium) {
            justify-content: flex-start;
        }

    }

    &__premium-icon {
        width: rem(20);
        height: rem(20);
        fill: $secondary-color;
        margin-right: rem(5);
    }

}




