.c-contactselect {
    margin: 0;
    list-style: none;

    &__item {
        margin-bottom: rem(12);
        padding: rem(10 10);
        background: #fafafa;
        border: 1px solid #e0e0e0;
        border-radius: rem(5);

        .cell {
            margin: rem(0 10);
        }

        input[type="radio"] {
            margin-bottom: rem(10);
        }

        label {
            cursor: pointer;
        }
    }

    &__item--checked {
        background-color: rgba(get-color(secondary), 0.1);
        border-color: get-color(secondary);
    }
}
