/* ==========================================================================
   Preloader Styling
   ========================================================================== */


.c-preloader-overlay {
    position: absolute;
    z-index:99;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($white,.9);
}

.c-preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__icon {
        width: rem(56);
        height: rem(56);
        max-height: 70%;
        transform-origin: 50% 56%;
        animation: rotateLogo 1.5s infinite linear;
        position: relative;
        fill: $primary-color !important;
    }
}
