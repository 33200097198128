/* ==========================================================================
   lead
   ========================================================================== */

/**
 * base styles
 */

.c-lead {
    &, & p {
        font-size: $lead-font-size-small;
        line-height: 1.5;

        @include breakpoint(medium) {
            font-size: $lead-font-size;
        }
    }

    &--large, &--large p {
        font-size: $lead-font-size;

        @include breakpoint(large) {
            font-size: $lead-font-size-large;
            line-height: 1.4;
        }

    }
}
