/* ==========================================================================
   User Dashboard Hero area
   ========================================================================== */

/**
 * c-user-dashboard-hero
 * Styling here will take care of the hero area on the user dashboard / profile page. It will properly position elements found within it.
 */

.c-user-dashboard-hero {
    margin: 3rem auto;
    text-shadow: $hero-text-shadow;

    @include breakpoint(medium) {
        margin-top: 4rem;
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: rem(16) auto;

        height: rem(100);
        width: rem(100);

        @include breakpoint(large) {
            height: rem(160);
            width: rem(160);
        }
    }

    &__avatar-icon {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
        background-color: $white;
        fill: $primary-color;
    }

    &__link {
        font-weight: 700;

        @include breakpoint(medium) {
            font-size: $lead-font-size-medium;
        }
    }

    &__link-icon {
        width: 1.2em;
        height: 1.2em;
    }

    .c-media-upload {
        position: absolute;
        top: 10%;
        left: 90%;
        &:after {
            content: 'Das Profilbild kann nicht zurecht geschnitten werden. Bitte verwenden Sie ein Foto im Querformat oder Quadratisch.';
            font-family: $body-font-family;
            color: #333;
            font-size: rem(12);
            line-height: 1;
            display: none;
            position: absolute;
            left: rem(20);
            text-align: left;
            background-color: $white;
            border-radius: rem(5);
            padding: rem(5);
        }
        &:hover {
            &:after {
                display: block;
            }
        }
    }

    /*
    .c-media-upload {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255, 0.4);

        input[type="file"] {
            display: none;
        }

        &__icon {
            width: 100%;
            height: 100%;
            padding: 20%;
            fill: get-color(primary);
        }

        .filepond--root {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
        }

        .filepond--panel-root {
            background: transparent !important;
        }

        .filepond--drop-label {
            font-size: 0;
            text-indent: rem(-500);
            width: 100%;
            height: 100%;
            transform: none !important;

            label {
                padding: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
    }
    */
}
