/* ==========================================================================
   Backlink
   ========================================================================== */

/**
 * Backlink component
 * A link with a back arrow
 */

.c-backlink {
    color: $white;
    display: inline-flex;
    margin-bottom: $paragraph-margin-bottom;

    &__icon {
        width: rem(23);
        height: rem(23);
        fill: $white;
        transform: rotate(180deg);
        transition: fill .3s ease-in-out;
        margin-right: rem(10);
    }

    &__link-text {
        font-weight: 700;
    }

    &:hover {
        color: $body-font-color;

        .c-backlink__icon {
            fill: $body-font-color;
         
        }
    }
}
