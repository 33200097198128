.c-logoline {
    margin: $typogrid 0;

    &:last-of-type {
        margin-bottom: $typogrid*3;
    }

    &__list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: $typogrid*1 auto;
        padding: 0;
        list-style: none;

        @include breakpoint(large) {
            max-width: 90vw;
        }
    }

    &__image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: rem(20 30);
    }
}
