.browserupgrade {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
    text-align: center;
}

html {
    overflow-y: scroll;
    overflow-x: hidden;

    &.is-magazine {

        .grid-container.content{

            max-width: rem(740);
        }

    }
}


body {
    visibility: visible;
    opacity: 1;
    overflow-x: hidden;
}

// this is for fixing the footer to the bottom of the viewport (because of min-height not working in IE)
.l-page-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.l-page-meta-nav, .l-page-header, .l-page-content, .l-page-footer {
    width: 100%; // needed for firefox and edge display error
    flex: 0 0 auto;
}

.l-page-content {
    // add padding if no hero is present in the content area
    padding-top: rem(130);
    flex: 1 0 auto;

    &.has-hero {
        padding-top: 0;
    }
}

.grid-background[class*="u-background--"] {
    padding: $typogrid*4 0;
    margin: $typogrid*4 0;
    transform: skew(0deg, -4deg);

    & + & {
        padding-top: 0;
        margin-top: $typogrid * -8;
    }

    > .grid-container {
        transform: skew(0deg, 4deg);
    }

    &.u-noskew {
        transform: none;

        > .grid-container {
            transform: none;
        }
    }
}

.grid-container {
    @include breakpoint(medium only) {
        max-width: calc(100vw - 2rem);
    }
}

// Generic Blockquote Styling

blockquote {
    background-color: $secondary-color;
    padding: $global-margin;
    font-weight: 700;
    position: relative;

    cite {
        max-width: 80%;
    }

    p:first-child {
        margin-top: rem(50);
    }

    &::before {
        content: "»";
        font-size: rem(70);
        position: absolute;
        top: $global-margin;
        opacity: .6;
    }

    &::after {
        content: "»";
        font-size: rem(70);
        position: absolute;
        bottom: $global-margin;
        right: $global-margin;
        opacity: .6;
        transform: scaleX(-1);// flips the icon
    }
}
