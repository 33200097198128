.l-pricing-table {
    overflow: hidden;
    margin: 0 0 $global-margin;

    @include breakpoint(medium) {
        box-shadow: $global-shadow;
    }
}


.c-pricing-table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: $white;
    width: 100%;
    margin: 0 0 $global-margin;
    text-align: center;
    list-style-type: none;
    background: rgba(get-color(secondary),0.08);
    box-shadow: $global-shadow;

    @include breakpoint(medium) {
        margin: 0;
        border-left: solid 1px darken(get-color(secondary), 5%);
        box-shadow: none;
    }

    li {
        padding: 0.875rem 1.125rem;
    }

    .c-pricing-table__title {
        height: rem(52);
        background-color: get-color(secondary);
        color: $white;
        font-weight: $global-weight-bold;
        font-size: $lead-font-size-large;
        text-transform: uppercase;
    }

    .c-pricing-table__image {
        padding: 0;
    }

    .c-pricing-table__price {
        height: rem(52);
        background-color: rgba(get-color(secondary),0.2);
        font-size: $lead-font-size-medium;
        font-weight: $global-weight-bold;
    }

    .c-pricing-table__description {
        font-weight: $global-weight-bold;
    }

    .c-pricing-table__highlights {
        padding: 0;
        flex-grow: 1;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                border-top: dotted 1px $medium-gray;
            }
        }
    }

    :last-child {
        margin-bottom: 0;
    }

    &--karriereseite {
        z-index: 2;
        box-shadow: $global-shadow;
        background: darken(rgba(get-color(primary),0.15), 2%);
        border-left: none;

        @include breakpoint(medium only) {
            width: 100% !important;
        }

        .c-pricing-table__title {
            background-color: get-color(primary);
        }

        .c-pricing-table__price {
            background-color: rgba(get-color(primary),0.2);
        }
    }
  }
