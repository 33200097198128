/* ==========================================================================
   Sharing list
   ========================================================================== */

/**
 * c-sharing
 * A list of sharing buttons on the jobs detail page
 */

 .c-sharing {
     box-shadow: $global-shadow;
     margin-bottom: $paragraph-margin-bottom;

     &__list {
         list-style-type: none;
         margin: 0;
     }
 }