/* ==========================================================================
   download-card
   ========================================================================== */

.c-download-card {

  &__heading {
    text-align: center;
    color: $primary-color;
    margin: 0 0 rem(10) 0;
  }

  &__image-outer-wrapper {
      overflow: hidden;
      height: 0;
      padding-top: 10rem;
      position: relative;

    @include breakpoint(large) {
      padding-top: 65%;
    }

  }

  &__image-wrapper {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__image {
    width: auto;
      margin-left: auto;
      margin-right: auto;
    box-shadow: 0.025rem 0.025rem 0.94rem rgba(0,0,0,0.4) !important; //// todo mn
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: auto;

    &--ls {
      max-height: rem(110); // landscape image
    }
    &--pt {
      max-height: rem(135); // portrait image
    }

  }

  &__link {
    display: block;
    margin-top: rem(10);
    line-height: 3.5;
    text-align: center;
    border: rem(3) solid $primary-color;
    font-family: $header-font-family;
  }

}
