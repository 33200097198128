.c-job-filtercriteria {
    &__intro {
        display: block;
        margin-bottom: rem(-5);
        font-weight: $global-weight-bold;
    }

    &__selected {
        display: none;
        border: none;
        border-bottom: rem(1) solid $white;
        color: rgba($white, 0.9);
        margin: rem(0 0 15 5);
        padding: rem(0 30 0 0);
        background: transparent;
        background-size: rem(24) rem(34);
        background-repeat: no-repeat;
        background-position: right center;
        background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g transform="matrix(-2.02973e-16,-1.26217,1.05791,-1.70124e-16,4.51006,67.0393)"><path d="M0.1,43L0,43.15L0.28,43.3L25.25,86L27,85L2.43,43L27,1L25.24,0L0.24,42.7L0,42.85L0.1,43Z" style="fill:white;fill-rule:nonzero;"/></g></svg>');

        &.has-selected {
            color: $white;
        }
    }

    &.c-job-filtercriteria--hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $global-margin 0;
        color: #fff;
        text-shadow: $hero-text-shadow;

        .c-job-filtercriteria__item {
            position: relative;
        }

        .c-job-filtercriteria__intro {
            display: inline-block;
        }

        .c-job-filtercriteria__selected {
            display: inline-block;
        }

        select {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            background: $white;
            background-repeat: no-repeat;
            opacity: 0;
        }

        .c-job-filtercriteria__intro, .c-job-filtercriteria__selected {
            @include breakpoint(medium) {
                font-size: $lead-font-size;
            }

            @include breakpoint(large) {
                font-size: $lead-font-size-large;
            }
        }
    }
}
