/* ==========================================================================
   navigation
   ========================================================================== */

/**
 * Outer navigation
 */

.c-nav {
    text-align: right;

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        display: inline-block;
        margin: rem(0 10);
        position: relative;

        &:hover {
            > .c-sub-nav {
                visibility: visible;
                opacity: 1;
                height: auto;
                animation: fadein 0.3s linear;
            }
        }
    }

    &__link {
        color: $body-font-color;
        display: flex;
        height: rem(90);
        align-items: center;
        font-size: rem(14);
        &:focus {
            outline: none;
            text-decoration: underline;
        }

        @media screen and (min-width: 1130px) {
            font-size: rem(17);
        }
    }

    &__loginicon {
        position: relative;
        top: rem(-1);
        width: rem(25);
        height: rem(25);
        margin-left: rem(5);
        fill: $primary-color;
        vertical-align: middle;
    }

    // control visibility
    @include visually-hidden();

    @include breakpoint(large) {
        @include visually-hidden-undo();
    }

    .is-sticky &__link {
        height: rem(60);
    }
}


/* job count bouble */
.c-nav__link.first {
    font-weight: $global-weight-bold;
}
.badge--jobcount {
    position: absolute;
    background: $badge-alert-color;
    color: #fff;
    font-weight: 700;
    font-size: rem(11);
    display: inline-block;
    line-height: 1;
    border-radius: rem(10);
    padding: rem(3 5);
    top: 50%;
    right: rem(-5);
    transform: translateX(100%) translateY(-50%);
    margin-top: rem(-7);
}

/**
 * Sub navigation
 */

.c-sub-nav {
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: rem(10);
    text-align: center;
    width: fit-content;
    background-color: $white;
    left: 50%;
    transform: translate(-50%);
    visibility: hidden;
    opacity: 0;
    height: 0;

    &__link {
        display: block;
        padding: rem(8 10);

        &:hover {
            color: $body-font-color;
        }
    }
}

