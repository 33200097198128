/* ==========================================================================
   header layout
   ========================================================================== */

/**
 * base styles
 */

.l-page-header {
    z-index: 100;
    position: absolute;
    width: 100%;
    max-width: $header-width;
    left: 50%;
    transform: translateX(-50%);

    &__content {
        background: $white;
        box-shadow: $card-shadow;
        position: relative;
        padding: rem(10) 0;
    }

    @include breakpoint(large) {
        margin-top: rem(40);
        padding-left: $header-side-margin/2;
        padding-right: $header-side-margin/2;
    }

    @media all and (min-width: rem(1184)) {
        padding-left: $header-side-margin;
        padding-right: $header-side-margin;
    }

}

/**
 * sticky styles and animation
 */

 .l-page-header.is-sticky {

    position: fixed;
    width: 100%;
    max-width: 100%;
    top: 0;
    z-index: 10;
    animation-duration: 1s;
    animation-name: sticky_transition;
    font-size: rem(14);

    // overriding styles
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    left: 0;
    right: 0;
    transform: none;

    .l-page-header__content {

        @include breakpoint(medium) {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }

}


@keyframes sticky_transition {
    from {
    position: fixed;
    top: rem(-192);
    }

    to {
    position: fixed;
    top: 0;
    }
}
