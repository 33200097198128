.CodeMirror {
    color: #000;
    direction: ltr;
    font-family: monospace;
    height: 300px;
}

.CodeMirror-lines {
    padding: 4px 0
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
    padding: 0 4px
}

.CodeMirror-gutter-filler,
.CodeMirror-scrollbar-filler {
    background-color: #fff
}

.CodeMirror-gutters {
    background-color: #f7f7f7;
    border-right: 1px solid #ddd;
    white-space: nowrap
}

.CodeMirror-linenumber {
    color: #999;
    min-width: 20px;
    padding: 0 3px 0 5px;
    text-align: right;
    white-space: nowrap
}

.CodeMirror-guttermarker {
    color: #000
}

.CodeMirror-guttermarker-subtle {
    color: #999
}

.CodeMirror-cursor {
    border-left: 1px solid #000;
    border-right: none;
    width: 0
}

.CodeMirror div.CodeMirror-secondarycursor {
    border-left: 1px solid silver
}

.cm-fat-cursor .CodeMirror-cursor {
    background: #7e7;
    border: 0 !important;
    width: auto;
}

.cm-fat-cursor div.CodeMirror-cursors {
    z-index: 1
}

.cm-fat-cursor-mark {
    -moz-animation: blink 1.06s steps(1) infinite;
    -webkit-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
    background-color: rgba(20, 255, 20, .5);
}

.cm-animate-fat-cursor {
    -moz-animation: blink 1.06s steps(1) infinite;
    -webkit-animation: blink 1.06s steps(1) infinite;
    animation: blink 1.06s steps(1) infinite;
    background-color: #7e7;
    border: 0;
    width: auto;
}

@-moz-keyframes blink {
    50% {
        background-color: transparent
    }
}

@-webkit-keyframes blink {
    50% {
        background-color: transparent
    }
}

@keyframes blink {
    50% {
        background-color: transparent
    }
}

.cm-tab {
    display: inline-block;
    text-decoration: inherit
}

.CodeMirror-rulers {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: -50px;
}

.CodeMirror-ruler {
    border-left: 1px solid #ccc;
    bottom: 0;
    position: absolute;
    top: 0;
}

.cm-s-default .cm-header {
    color: #00f
}

.cm-s-default .cm-quote {
    color: #090
}

.cm-negative {
    color: #d44
}

.cm-positive {
    color: #292
}

.cm-header,
.cm-strong {
    font-weight: 700
}

.cm-em {
    font-style: italic
}

.cm-link {
    text-decoration: underline
}

.cm-strikethrough {
    text-decoration: line-through
}

.cm-s-default .cm-keyword {
    color: #708
}

.cm-s-default .cm-atom {
    color: #219
}

.cm-s-default .cm-number {
    color: #164
}

.cm-s-default .cm-def {
    color: #00f
}

.cm-s-default .cm-variable-2 {
    color: #05a
}

.cm-s-default .cm-type, .cm-s-default .cm-variable-3 {
    color: #085
}

.cm-s-default .cm-comment {
    color: #a50
}

.cm-s-default .cm-string {
    color: #a11
}

.cm-s-default .cm-string-2 {
    color: #f50
}

.cm-s-default .cm-meta {
    color: #555
}

.cm-s-default .cm-qualifier {
    color: #555
}

.cm-s-default .cm-builtin {
    color: #30a
}

.cm-s-default .cm-bracket {
    color: #997
}

.cm-s-default .cm-tag {
    color: #170
}

.cm-s-default .cm-attribute {
    color: #00c
}

.cm-s-default .cm-hr {
    color: #999
}

.cm-s-default .cm-link {
    color: #00c
}

.cm-s-default .cm-error {
    color: red
}

.cm-invalidchar {
    color: red
}

.CodeMirror-composing {
    border-bottom: 2px solid
}

div.CodeMirror span.CodeMirror-matchingbracket {
    color: #0b0
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
    color: #a22
}

.CodeMirror-matchingtag {
    background: rgba(255, 150, 0, .3)
}

.CodeMirror-activeline-background {
    background: #e8f2ff
}

.CodeMirror {
    background: #fff;
    overflow: hidden;
    position: relative;
}

.CodeMirror-scroll {
    height: 100%;
    margin-bottom: -30px;
    margin-right: -30px;
    outline: 0;
    overflow: scroll !important;
    padding-bottom: 30px;
    position: relative;
}

.CodeMirror-sizer {
    border-right: 30px solid transparent;
    position: relative;
}

.CodeMirror-gutter-filler,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-vscrollbar {
    display: none;
    position: absolute;
    z-index: 6;
}

.CodeMirror-vscrollbar {
    overflow-x: hidden;
    overflow-y: scroll;
    right: 0;
    top: 0;
}

.CodeMirror-hscrollbar {
    bottom: 0;
    left: 0;
    overflow-x: scroll;
    overflow-y: hidden;
}

.CodeMirror-scrollbar-filler {
    bottom: 0;
    right: 0;
}

.CodeMirror-gutter-filler {
    bottom: 0;
    left: 0;
}

.CodeMirror-gutters {
    left: 0;
    min-height: 100%;
    position: absolute;
    top: 0;
    z-index: 3
}

.CodeMirror-gutter {
    display: inline-block;
    height: 100%;
    margin-bottom: -30px;
    vertical-align: top;
    white-space: normal;
}

.CodeMirror-gutter-wrapper {
    background: 0 0 !important;
    border: none !important;
    position: absolute;
    z-index: 4;
}

.CodeMirror-gutter-background {
    bottom: 0;
    position: absolute;
    top: 0;
    z-index: 4
}

.CodeMirror-gutter-elt {
    cursor: default;
    position: absolute;
    z-index: 4
}

.CodeMirror-gutter-wrapper ::selection {
    background-color: transparent
}

.CodeMirror-gutter-wrapper ::-moz-selection {
    background-color: transparent
}

.CodeMirror-lines {
    cursor: text;
    min-height: 1px
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
    background: 0 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    border-width: 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    -webkit-font-variant-ligatures: contextual;
    font-variant-ligatures: contextual;
    line-height: inherit;
    margin: 0;
    overflow: visible;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    white-space: pre;
    word-wrap: normal;
    z-index: 2;
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
    white-space: pre-wrap;
    word-break: normal;
    word-wrap: break-word;
}

.CodeMirror-linebackground {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0
}

.CodeMirror-linewidget {
    padding: .1px;
    position: relative;
    z-index: 2;
}

.CodeMirror-rtl pre {
    direction: rtl
}

.CodeMirror-code {
    outline: 0
}

.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber,
.CodeMirror-scroll,
.CodeMirror-sizer {
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

.CodeMirror-measure {
    height: 0;
    overflow: hidden;
    position: absolute;
    visibility: hidden;
    width: 100%;
}

.CodeMirror-cursor {
    pointer-events: none;
    position: absolute;
}

.CodeMirror-measure pre {
    position: static
}

div.CodeMirror-cursors {
    position: relative;
    visibility: hidden;
    z-index: 3
}

div.CodeMirror-dragcursors {
    visibility: visible
}

.CodeMirror-focused div.CodeMirror-cursors {
    visibility: visible
}

.CodeMirror-selected {
    background: #d9d9d9
}

.CodeMirror-focused .CodeMirror-selected {
    background: #d7d4f0
}

.CodeMirror-crosshair {
    cursor: crosshair
}

.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
    background: #d7d4f0
}

.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
    background: #d7d4f0
}

.cm-searching {
    background-color: #ffa;
    background-color: rgba(255, 255, 0, .4)
}

.cm-force-border {
    padding-right: .1px
}

@media print {
    .CodeMirror div.CodeMirror-cursors {
        visibility: hidden
    }
}

.cm-tab-wrap-hack:after {
    content: ''
}

span.CodeMirror-selectedtext {
    background: 0 0
}


.CodeMirror {
    border: 1px solid #ddd;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-sizing: border-box;
    font: inherit;
    height: auto;
    padding: 10px;
    word-wrap: break-word;
    z-index: 1;
}

.CodeMirror-scroll {
    cursor: text;
}

.CodeMirror-fullscreen {
    background: #fff;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
    bottom: 0;
    height: auto;
    left: 0;
    position: fixed !important;
    right: 0;
    top: 50px;
    z-index: 9;
}

.CodeMirror-sided {
    width: 50% !important;
}

.CodeMirror-placeholder {
    opacity: .5;
}

.CodeMirror-focused .CodeMirror-selected {
    background: #d9d9d9;
}

.editor-toolbar {
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
    border-top: 1px solid #bbb;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 0 10px;
    position: relative;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.editor-toolbar:after,
.editor-toolbar:before {
    content: ' ';
    display: block;
    height: 1px;
}

.editor-toolbar:before {
    margin-bottom: 8px
}

.editor-toolbar:after {
    margin-top: 8px
}

.editor-toolbar.fullscreen {
    background: #fff;
    border: 0;
    box-sizing: border-box;
    height: 50px;
    left: 0;
    opacity: 1;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
    padding-top: 10px;
    position: fixed;
    top: 0;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
}

.editor-toolbar.fullscreen::before {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    height: 50px;
    left: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    width: 20px;
}

.editor-toolbar.fullscreen::after {
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    height: 50px;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 20px;
}

.editor-toolbar button {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-decoration: none !important;
    width: 30px;
}

.editor-toolbar button.active,
.editor-toolbar button:hover {
    background: #fcfcfc;
    border-color: #95a5a6;
}

.editor-toolbar i.separator {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #fff;
    color: transparent;
    display: inline-block;
    margin: 0 6px;
    text-indent: -10px;
    width: 0;
}

.editor-toolbar button:after {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 65%;
    position: relative;
    top: 2px;
    vertical-align: text-bottom;
}

.editor-toolbar button.heading-1:after {
    content: "1";
}

.editor-toolbar button.heading-2:after {
    content: "2";
}

.editor-toolbar button.heading-3:after {
    content: "3";
}

.editor-toolbar button.heading-bigger:after {
    content: "▲";
}

.editor-toolbar button.heading-smaller:after {
    content: "▼";
}

.editor-toolbar.disabled-for-preview button:not(.no-disable) {
    opacity: .6;
    pointer-events: none;
}

@media only screen and (max-width: 700px) {
    .editor-toolbar i.no-mobile {
        display: none;
    }
}

.editor-statusbar {
    color: #959694;
    font-size: 12px;
    padding: 8px 10px;
    text-align: right;
}

.editor-statusbar span {
    display: inline-block;
    margin-left: 1em;
    min-width: 4em;
}

.editor-statusbar .lines:before {
    content: 'lines: '
}

.editor-statusbar .words:before {
    content: 'words: '
}

.editor-statusbar .characters:before {
    content: 'characters: '
}

.editor-preview-full {
    box-sizing: border-box;
    display: none;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 7;
}

.editor-preview-side {
    border: 1px solid #ddd;
    bottom: 0;
    box-sizing: border-box;
    display: none;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 50px;
    width: 50%;
    word-wrap: break-word;
    z-index: 9;
}

.editor-preview-active-side {
    display: block
}

.editor-preview-active {
    display: block
}

.editor-preview {
    background: #fafafa;
    padding: 10px;
}

.editor-preview > p {
    margin-top: 0
}

.editor-preview pre {
    background: #eee;
    margin-bottom: 10px;
}

.editor-preview table td,
.editor-preview table th {
    border: 1px solid #ddd;
    padding: 5px;
}

.cm-s-easymde .cm-tag {
    color: #63a35c;
}

.cm-s-easymde .cm-attribute {
    color: #795da3;
}

.cm-s-easymde .cm-string {
    color: #183691;
}

.cm-s-easymde .cm-header-1 {
    font-size: 200%;
    line-height: 200%;
}

.cm-s-easymde .cm-header-2 {
    font-size: 160%;
    line-height: 160%;
}

.cm-s-easymde .cm-header-3 {
    font-size: 125%;
    line-height: 125%;
}

.cm-s-easymde .cm-header-4 {
    font-size: 110%;
    line-height: 110%;
}

.cm-s-easymde .cm-comment {
    background: rgba(0, 0, 0, .05);
    border-radius: 2px;
}

.cm-s-easymde .cm-link {
    color: #7f8c8d;
}

.cm-s-easymde .cm-url {
    color: #aab2b3;
}

.cm-s-easymde .cm-quote {
    color: #7f8c8d;
    font-style: italic;
}

// hide Markdown
.cm-formatting.cm-formatting-strong, .cm-formatting.cm-formatting-em, .cm-formatting.cm-formatting-strikethrough, .cm-formatting.cm-formatting-header {
    display: none;
}
