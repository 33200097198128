/* ==========================================================================
   Button
   ========================================================================== */

/**
 * base styles
 */


.c-button {
    @include button;
    width: auto;
    font-weight: $global-weight-bold;
    line-height: $typogrid * 2;
    position: relative;
    overflow: hidden; // required for the rounded edges of the button to appear when we have an icon with background color
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: $global-shadow-small;

    &__icon-background {
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: em(44);
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: em(22);
        height: em(22);
        fill: $white;
    }

    &__label {
        display: inline-block;
        padding: $button-padding;
    }


    /* Variations
        ========================================================================== */

    &--no-shadow {
        box-shadow: none;
    }

    &--icon {
        text-align: left;
    }

    &--icon &__label {
        padding-left: unitless-calc(get-side($button-padding, left)) + em(22);
    }

    &--icon.c-button--icon-with-background &__label {
        padding-left: unitless-calc(get-side($button-padding, left)) + em(44);
    }

    &--medium {
        font-size: rem(15);

        .c-button__label {
            padding-top: rem(get-side($button-padding, top) * 0.9);
            padding-bottom: rem(get-side($button-padding, bottom) * 0.9);
        }
    }

    &--small {
        font-size: rem(12);

        .c-button__label {
            padding-top: rem(get-side($button-padding, top) * 0.7);
            padding-bottom: rem(get-side($button-padding, bottom) * 0.7);
        }
    }

    &--delete {
        background-color: map-get($foundation-palette, 'alert');
    }

    // full width buttons
    &--fullwidth {
        @include button-expand;
    }

    // Colors
    @each $name, $styles in $button-styles {
        @if $name == 'whiteee' {
            $name: "white"
        }
        &--#{$name} {
            @include button-style(map-get($styles, 'background'), map-get($styles, 'background-hover'), map-get($styles, 'text-color'));

            svg {
                fill: map-get($styles, 'text-color');
            }
        }
    }

    // Hollow style
    &--outline, &--transparent {
        @include button-hollow;
        // @include button-hollow-style;
        //padding-top: (unitless-calc(get-side($button-padding, top))*16) - 1px;
        //padding-bottom: (unitless-calc(get-side($button-padding, bottom))*16) - 1px;
        //text-shadow: none;
        box-shadow: none;
        border: $button-hollow-border-width solid map-get(map-get($button-styles, 'primary'), 'border-color');
        color: map-get(map-get($button-styles, 'primary'), 'border-color');

        @each $name, $styles in $button-styles {
            @if $name == 'whiteee' {
                $name: "white"
            }
            &.c-button--#{$name} {
                //@include button-hollow-style(map-get($styles, 'background'), map-get($styles, 'background-hollow-scale'));
                border: $button-hollow-border-width solid map-get($styles, 'border-color');
                color: map-get($styles, 'border-color');

                &:hover, &:focus {
                    //color: map-get($styles, 'hover-text-color');
                    border-color: darken(map-get($styles, 'border-color'), 5%);
                }

                .c-button--icon-with-background .c-button__icon-background {
                    border-right: $button-hollow-border-width solid map-get($styles, 'border-color');
                }

                .c-button__icon {
                    fill: map-get($styles, 'border-color');
                }
            }

            &:hover .c-button__label, &:focus .c-button_label {
                color: map-get($styles, 'hover-text-color');
            }
        }
    }

    &--transparent {
        background: transparent !important;
        border: none !important;
        margin-bottom: rem(-5); // this is helpful when used as last button in a modal. this might need refactoring with other usecases
        font-weight: $global-weight-normal;

        .c-button__label {
            padding: 0;
        }
    }



    /*
          &--white {
                background-color: $white;
                transition: background-color 0.3s ease-in-out;
                color: $dark-gray;
                border: none;

                .c-button__icon-background {
                      background-color: $secondary-color;
                }

                .c-button__icon {
                      fill: $white;
                }

                &:hover {
                      background-color: darken($white,10%);
                }
          }

          &--primary {

                .c-button__icon-background {
                      background-color: darken($primary-color,10%);
                }

                .c-button__icon {
                      fill: $white;
                }


          }
    */
    &--bookmark { // todo eventually renaming the button instances. I have no better idea at the moment. I only found this style used here for the bookmarks.
        padding: rem(10);
        color: $dark-gray;
        font-size: $small-font-size;
        display: flex;
        align-items: center;
        justify-content: center;

        .c-button__icon {
            fill: $secondary-color;
            margin-right: rem(10);
            flex: 0 0 auto;
        }

        &-text {
            flex: 0 0 auto;
        }

        &:hover {
            .c-button__icon {
                fill: $white;
            }
        }

        @include breakpoint(large) {
            justify-content: flex-start;
            padding: rem(8 10);
        }

    }

    // Disabled style
    &.disabled,
    &[disabled] {
        @include button-disabled;
        display: inline-block;  /* For IE11/ MS Edge bug */
        pointer-events: none;

        @each $name, $styles in $button-styles {
            &.#{$name} {
                @include button-disabled(map-get($styles, 'background'));
            }
        }
    }

    &--workshop {
        @include button-hollow;
        @include button-hollow-style;
        box-shadow: none;
        border: $button-hollow-border-width solid map-get(map-get($button-styles, 'primary'), 'border-color');
        color: map-get(map-get($button-styles, 'primary'), 'border-color');

        @each $name, $styles in $button-styles {
            @if $name == 'whiteee' {
                $name: "white"
            }
            &.c-button--#{$name} {
                //@include button-hollow-style(map-get($styles, 'background'), map-get($styles, 'background-hollow-scale'));
                border: none;
                color: map-get($styles, 'border-color');

                .c-button__icon {
                    fill: map-get($styles, 'border-color');
                }
            }

            &:hover .c-button__label, &:focus .c-button_label {
                color: map-get($styles, 'hover-text-color');
            }
        }

    }


    /* bottom positioned
    ========================================================================== */

    &--fixed-bottom-small-only {
        @include breakpoint(small only) {
            position: fixed;
            bottom: rem(5);
            z-index: 5;
            margin: 0;
            left: rem(5);
            right: rem(5);
            width: auto;
        }
    }

}
