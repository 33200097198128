/* ==========================================================================
   Registration Form - c-register / c.register-cv chunk
   ========================================================================== */

.c-register-cv {
    max-width: rem(750);
    margin: 0 auto;
    padding: rem(40 0 16);

    @include breakpoint(medium) {
        padding: rem(60 0);
    }
}

/**
 * base styles
 */
/*
.c-rrrregister-cv {

    padding: rem(20 0);
    margin: auto;
    max-width: rem(750);

    @include breakpoint(medium) {
        padding: rem(60 0);
    }

    &__form {
        position: relative;
    }

    &__heading {
        color: $white;
        font-weight: 700;
        font-size: rem(22);
        margin-bottom: $paragraph-margin-bottom * 2;
        line-height: 1.4;
    }

    &__label {
        display: inline;
        color: $white;
        font-size: rem(18);

    }

    &__select, &__select:focus {
        font-size: rem(18);
        display: inline-block;
        width: auto;
        background-color: transparent;
        border: none;
        color: rgba(255,255,255,.7);
        height: rem(40);
        margin: rem(0 0 15 8);
        padding: rem(0 25 0 0);
        border-bottom: rem(1) solid $white;
        background-size: rem(20) rem(30);
        background-position: right rem(-24) center;
        background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g transform="matrix(-2.02973e-16,-1.26217,1.05791,-1.70124e-16,4.51006,67.0393)"><path d="M0.1,43L0,43.15L0.28,43.3L25.25,86L27,85L2.43,43L27,1L25.24,0L0.24,42.7L0,42.85L0.1,43Z" style="fill:white;fill-rule:nonzero;"/></g></svg>');
    }

    &__link {
        color: $white;
        font-weight: 700;

        &:hover {
            color: $primary-color;
        }
    }

    &__contact {
        // fieldset containing contact info
        margin: $paragraph-margin-bottom 0 0;

        @include breakpoint(medium) {
            margin: 0;
        }
    }

    // checkbox selected effect
    input[type=checkbox] {
        transition: background-color .03s ease-in-out;
    }

    input[type=checkbox]:checked {
        ~ .u-checkbox {
            background-color: $primary-color;
        }
    }

    &__button-wrapper {
        display: flex;
        align-items: flex-end;
    }

}
*/
