/* ==========================================================================
   Slider
   ========================================================================== */

/**
 * c-slider
 *
 */

 .c-slider {
    max-width: 100%;
    margin: auto;
    position: relative;


    .c-testimonial {
        margin-bottom: 0;
    }
}


.glide {
    overflow: hidden;

    &__slide {
        opacity: .5;
        transition: opacity .2s ease-in-out;
    }

    &__slide--active {
        opacity: 1;

    }

    &__icon {
        width: rem(40);
        height: rem(40);
        fill: $medium-gray;
        display: block;

        transition: fill .3s ease-in-out;

        &:hover {
            fill: get-color(secondary);
        }
    }


    &__icon--left {
        transform: scaleX(-1);// flips the icon
    }

    // controls

    &__controls {
        position: absolute;
        max-width: $global-width;
        margin: auto;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
        z-index: 5;

        display: none;

        @include breakpoint(medium) {
            display: block;
        }
    }

    &__button {
        position: absolute;
        outline: none;

        &:focus {
            svg {
                fill: get-color(primary);
            }
        }
    }

    &__button--right {
        right: rem(16);

        @include breakpoint(large) {
            right: rem(-32);
        }
    }

    &__button--left {
        left: rem(16);

        @include breakpoint(large) {
            left: rem(-32);
        }
    }


    // bullets
    &__bullets {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: $global-margin;
    }

    &__bullet {
        width: rem(15);
        height: rem(15);
        display: block;
        background-color: $light-gray;
        border-radius: 50%;
        margin-right: rem(10);
        transition: background-color .3s ease-in-out;

        &:focus {
            outline-width: 1px;
            outline-color: $white;
        }

        &:last-child {
            margin-right: 0;
        }

        &--active, &:hover {
            background-color: $secondary-color;
        }
    }

}


