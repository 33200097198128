/* ==========================================================================
   # ACCORDION Styles Used for FAQ component
   ========================================================================== */

/*
 * highly inspired by https://codepen.io/abergin/pen/ihlDf
 */

.flipIn {
  animation: flipdown 0.5s ease both;
}

.c-accordion {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: $typogrid*2 0 $typogrid*4;
  border-bottom: 1px solid #e6e6e6;

  > li {
    position: relative;
    padding: 0 rem(15);
    margin: 0;
    border-top: 1px solid #d4d4d4;
    transition: height 0.3s ease-in-out;

    @extend .flipIn;

    @for $i from 1 through 20 {
      &:nth-of-type(#{$i}) {
        animation-delay: #{$i * 0.25 + 0.25}s;
      }
    }

    > i {
      position: absolute;
      margin-top: rem(20);
      right: rem(30);
      background-color: $primary-color;

      @include breakpoint(medium) {
        margin-top: rem(26);
      }

      &:before, &:after {
        content: "";

        transition: all 0.25s ease-in-out;

        position: absolute;
        background-color: inherit;
        width: 3px;
        height: 9px;
      }

      &:before {
        transform: translate(-2px, 0) rotate(45deg);
      }

      &:after {
        transform: translate(2px, 0) rotate(-45deg);
      }
    }

    > input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;

      @media only screen {
        &:checked {
          & ~ .c-accordion__content {
            margin-top: 0;
            max-height: 0;
            opacity: 0;
            transform: translate(0, 1rem);
          }

          & ~ i {
            &:before {
              transform: translate(2px, 0) rotate(45deg);
            }

            &:after {
              transform: translate(-2px, 0) rotate(-45deg);
            }
          }
        }
      }

      &:hover {
        & ~ .c-accordion__title {
          color: $primary-color;
        }
        & ~ i {
          background-color: $secondary-color;
        }
      }
    }
  }



  .c-accordion__title {
    display: block;
    background-color: inherit;
    margin: $typogrid*1 rem(35) $typogrid*1 0;
    cursor: pointer;
    font-size: rem(18);
    transition: color 0.25s ease-in-out;

    @include breakpoint(medium) {
      font-size: rem(22);
    }

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .c-accordion__content {
    position: relative;
    overflow: hidden;

    transition: all 0.3s ease-in-out, opacity 0.65s ease-in-out;

    opacity: 1;
    transform: translate(0, 0);
    z-index: 2;
  }
}

@keyframes flipdown {
  0% {
    opacity: 0;
    transform-origin: top center;
    transform: rotateX(-90deg);
  }

  5% {
    opacity: 1;
  }

  80% {
    transform: rotateX(8deg);
  }

  83% {
    transform: rotateX(6deg);
  }

  92% {
    transform: rotateX(-3deg);
  }

  100% {
    transform-origin: top center;
  }
}
