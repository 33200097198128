.c-notify {
    z-index: 99999;
    position: relative;
    display: block;
    width: 100%;
    padding: rem(16 40 16 40);
    background: get-color(secondary);
    box-shadow: $global-shadow;

    * {
        color: #fff;
        fill: #fff;
    }

    > :first-child {
        margin-top: 0;
    }
    > :last-child {
        margin-bottom: 0;
    }

    &--success {
        background-color: get-color(success);
    }

    &--warning {
        background-color: get-color(primary);
    }

    &--alert {
        background-color: get-color(alert);
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    &__close {
        position: absolute;
        top: 50%;
        right: rem(16);
        transform: translateY(-60%);
        font-size: rem(24);
        line-height: 1;
    }
}
