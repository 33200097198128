/* ==========================================================================
   compare box
   ========================================================================== */

/**
 * base styles
 */

.c-compare-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    margin-bottom: $global-margin;
    box-shadow: $global-shadow;

    &__area {
        background-color: $grey-01;
        flex: 0 1 100%;

        &-content {
            padding: rem(60);
        }

        @include breakpoint(medium) {
            flex: 0 1 auto;
            width: 50%;
        }

        &:first-child {
            border-bottom: rem(3) solid $light-gray;

            @include breakpoint(medium) {
                border: none;
                border-right: rem(3) solid $light-gray;
            }
        }

        &:last-child {
            background: $white linear-gradient(to bottom, rgba($light-gray, .5) 0%,rgba($light-gray, .2) 10%,$grey-01 100%);

            @include breakpoint(medium) {
                background: $white linear-gradient(to right, rgba($light-gray, .5) 0%,rgba($light-gray, .2) 5%,$grey-01 100%);
            }
        }

    }

    &__heading {
        display: block;
        text-align: center;
        font-weight: 700;
        font-size: $lead-font-size-large;
        margin-bottom: rem(20);
    }

    &__item {
        margin-bottom: rem(30);
    }

    &__icon-svg-wrapper {
        display: inline-flex;
        padding: rem(5) rem(20) rem(5) rem(5);
        margin-top: rem(-5);
    }

    &__icon-svg {
        width: rem(45);
        height: rem(45);
        fill: $primary-color;
    }

    &__text  {
        font-size: $lead-font-size;
        margin-bottom: 0;
        line-height: unset;
    }

}
