/* ==========================================================================
   User Dashboard Search Critieria
   ========================================================================== */

/**
 * This component consists of a button element that links to the profile edit page
 */

.c-user-dashboard-search-criteria {
    //background-color: $primary-color;
    padding: rem(16)  (map-get($grid-column-gutter, medium)/2);

    &__form-content{
        transition: max-height 1s ease-out; // open, close effect
        max-height: 0;
        overflow: hidden;
    }

    &__form {
        //padding: rem(10 20 5);
        //background-color: $white;
        //margin-top: $global-margin;
    }
}
