label {
    font-weight: 700;
    color: $dark-gray;
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
    padding: rem(4 0 4 0);

    &, &:focus {
        border-top: none;
        border-right: none;
        border-left: none;
    }

    &:focus {
        &::placeholder {
            color: get-color(primary);
        }
    }
}

select {
    & {
        display: inline-block;
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: rem(1) solid $dark-gray;
        color: $dark-gray;
        margin: rem(0 0 15 0);
        padding: rem(0 25 0 0);
        background-size: rem(20) rem(30);
        background-position: right rem(-24) center;
        background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g transform="matrix(-2.02973e-16,-1.26217,1.05791,-1.70124e-16,4.51006,67.0393)"><path d="M0.1,43L0,43.15L0.28,43.3L25.25,86L27,85L2.43,43L27,1L25.24,0L0.24,42.7L0,42.85L0.1,43Z" style="fill-rule:nonzero;"/></g></svg>');
    }
    &:focus {
        border: none;
        border-bottom: rem(1) solid get-color(primary);
        outline: none;
    }

    &.has-selected {
        color: $body-font-color;
    }
}


.c-checkbox {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 0 rem(12);
    cursor: pointer;
    font-weight: $global-weight-normal;
    padding-left: rem(26);
    line-height: 1.4;

    & > [type='checkbox'] {
        opacity: 0;
        position: absolute;
        cursor: pointer;
        pointer-events: none;
        &:focus {
            ~ .c-checkbox__checkelement  {
                box-shadow: 0 0 0 0.1875rem get-color(primary);
            }
        }
    }

    &__checkelement {
        z-index: 2;
        width: rem(18);
        height: rem(18);
        display: inline-block;
        background-color: $white;
        border: rem(1) solid $dark-gray;
        position: absolute;
        top: 2px;
        left: 0;
        transition: background-color .03s ease-in-out;
        cursor: pointer;
        box-shadow: 0 0 0 4px $white;

        ~ small {
            position: relative;
            top: 2px;
        }

    }
}

input[type="checkbox"]:checked ~ .c-checkbox__checkelement  {
    background-color: $white;
    background-size: rem(13) rem(13);
    background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"><g id="noun_1780646_cc" fill="#666666"><polygon id="Shape" points="14.997 32.6956 0.99728 45.8307 31.4877 87.694 90.9992 13.2639 77.8156 0.30596 31.4877 57.4269"></polygon></g></svg>');
    background-repeat: no-repeat;
    background-position: center center;
}


.c-radiobutton-wrapper { // wrapper class
    display: inline-block;
    padding: 0 $global-margin;
    label {
        display: inline;
        font-weight: normal;
    }
}
/*
 * White Form Variation
 */
.c-form--white {
    label {
        color: $white;

        a {
            color: #fff;
            text-decoration: underline;
        }
    }

    [type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
        border-color: $white;
        color: $white;

        &::placeholder {
            color: rgba($white, 0.8);
        }

        &:focus {
            color: $white;
            border-bottom: rem(1) solid $white;
            &::placeholder {
                color: $white;
            }
        }
    }

    select {
        color: rgba($white, 0.8);
        border-color: $white;
        background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g transform="matrix(-2.02973e-16,-1.26217,1.05791,-1.70124e-16,4.51006,67.0393)"><path d="M0.1,43L0,43.15L0.28,43.3L25.25,86L27,85L2.43,43L27,1L25.24,0L0.24,42.7L0,42.85L0.1,43Z" style="fill:white;fill-rule:nonzero;"/></g></svg>');

        &.has-selected {
           color: $white;
        }

        option {
           color: $body-font-color;
        }
    }

  & .c-checkbox__checkelement {
    background-color: $secondary-color;
    border: rem(1) solid $white;
    box-shadow: 0 0 0 4px get-color(secondary);
  }

}



//----------------- Floating Labels https://foundation.zurb.com/building-blocks/blocks/floated-label-wrapper.html

.u-floated-label-wrapper {
    position: relative;
    padding-top: rem(10);

    label {
      font-size: rem(12);
      opacity: 0;
      padding: 0;
      position: absolute;
      top: rem(32);
      transition: all 0.10s ease-in;
      z-index: -1;

      input[type=text], input[type=email], input[type=password] {
        border-radius: rem(4);
        font-size: 1.75em;
        padding: 0.5em;
      }

      &.has-show-label {
        opacity: 1;
        top: rem(0);
        z-index: 1;
        transition: all 0.3s ease-in;
      }
    }

  }

.c-search__field--hero, .c-search__field--hero:focus {
    background: #fff;
    border: none;
    padding: 16px 22px;
    height: auto;
    border-radius: 2px;
    box-shadow: 0 0 0.75rem rgba(get-color(primary),0.75);

    &:focus {
        color: get-color(primary);
    }
}


// TypeForm settings
.c-typeform {
    min-height: rem(500);
}
