/* ==========================================================================
   Job Header
   ========================================================================== */

/**
 * JOb Header area
 * In the header we show some meta data from the selected job
 */

.c-job-header {
    color: $white;
    margin-bottom: $paragraph-margin-bottom;
    text-shadow: $hero-text-shadow;

    &__title {
        margin: rem(32 0);
        font-size: rem(30);
        line-height: 1.2;
        word-break: break-word;

        @include breakpoint(medium) {
            margin: rem(40 0);
            font-size: rem(56);
            word-break: normal;
        }
    }

    &__details {
        font-size: $paragraph-font-size;
        font-family: $header-font-family;
        display: flex;
        align-items: flex-start;
        margin-bottom: rem(10);
        font-weight: 700;
    }

    &__text { // next to the icon
        flex-grow: 1;
        margin-left: rem(10);
    }

    &__meta {
        font-size: $small-font-size;
        color: $white;
    }

    &__icon {
        width: rem(23);
        height: rem(23);
        fill: $white;
        flex-shrink: 0;
    }

    &__link {
        color: $white;
        font-weight: 700;
        vertical-align: baseline;

        &:hover, &:active, &:visited, &:focus {
            color: $white;
            text-decoration: underline;

            .c-extended-header__link-icon {
                transform: translate(5px, 5px);
            }
        }

        &-icon {
            width: rem(30);
            height: rem(30);
            fill: $white;
            display: inline-block;
            transform: translate(0, 5px);
            transition: transform .3s ease-in-out;
        }
    }

    &__highlight {
        display: inline-block;
        position: relative;
        border-radius: 100%;
        width: rem(40);
        height: rem(40);
        background-color: $badge-alert-color;
        padding: rem(10);
        box-shadow: $card-shadow;

        &-text {
            position: absolute;
            top: 50%;
            left: 50%;
            font-size: rem(18);
            transform: translate(-50%, -50%);
            font-weight: 700;
        }
    }

}

/* Variations
   ========================================================================== */

.c-job-header__details--large {
    font-weight: 400;
    align-items: center;

    @include breakpoint(medium) {
        font-size: rem(30);

        .c-extended-header__icon {
            width: rem(30);
            height: rem(30);
        }
    }

}
