/* ==========================================================================
   Media Upload Styles
   ========================================================================== */

/**
 * c-media-upload
 * This could contain overrides of the filepond media upload script
 */

.c-media-upload {
    width: rem(200);

    .filepond--root {
        padding: 0;

        label {
            color: $white;
        }
    }

    .filepond--drop-label {
        margin: 0;
    }

    .filepond--panel-root {
        background-color: $primary-color;
        color: $white;
    }
}

/*
.c-media-upload {
    max-width: rem(300);
    width: 100%;
    position: relative;
}

.filepond--root {
    max-height: rem(136) !important;

    label {
        color: $white;
    }
}

.filepond--panel-root {
    background-color: $primary-color;
    color: $white;
}

.filepond--label-action {
    text-decoration-color: $white !important;
}

*/
