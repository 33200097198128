/* ==========================================================================
   Timetable component
   ========================================================================== */
.c-time-table-tabs {
    border-bottom: solid rem(2) $light-gray;
    margin-bottom: $typogrid*2;
    // tabs
    &__list {
        list-style: none;
        margin: 0;
        @include xy-grid;
        @include xy-grid-layout(1, '.c-time-table-tabs__item');
        @include breakpoint(medium) {
            @include xy-grid-layout(5, '.c-time-table-tabs__item', $gutters:15, $breakpoint:medium);
            margin-left: -0.46875rem;
        }
    }

    &__item {
        padding: rem(8);
        border: 0;
        margin-bottom: rem(15);
        align-items: center;
        transition: transform 0.2s;
        border-bottom: 1px solid;
        &:hover,
        &:focus {
            box-shadow: inset 0 0 0 5px get-color(tertiary);
            @include breakpoint(large) {
                transform: scale(1.2);
            }
            border-bottom-color: transparent;
        }

        &__link {
            display: block;
            text-align: center;
            color: inherit;
            line-height: 3rem;
            font-size: $small-font-size;
            font-family: $header-font-family;
            font-weight: $header-font-weight;
            outline: none;

        }

        &--alle {
            border-bottom-color: get-color(tertiary);
            .c-time-table-tabs__item__link {
                color: get-color(tertiary);
            }
            &:hover,
            &:focus {
                box-shadow: inset 0 0 0 5px get-color(tertiary);
            }

            &:after {
                color: get-color(tertiary);
            }

            &.is-active {
                box-shadow: inset 0 0 0 5px get-color(tertiary);
            }
        }


        &.is-active {
            position: relative;
            background-color: $hero-text-shadow;
            &__link {
                outline: 2px dotted $black;

            }
            &:after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                content: " ⇣";
                color: inherit;
            }
        }
    }

    &-content {

        &__panel {
            @include visually-hidden();

            &.is-active {
                @include visually-hidden-undo();
            }
        }
    }
}
