/* ==========================================================================
   Sharing list
   ========================================================================== */

/**
 * c-sociallinks
 *
 */

 .c-sociallinks {
     display: flex;
     justify-content: space-around;
     margin: 0 0 $paragraph-margin-bottom;
     padding: 0;

    @include breakpoint(medium) {
        justify-content: flex-start;
    }

     &__item {
        display: block;
        margin: rem(0 24 24 0);
        fill: get-color(tertiary);

        svg {
            width: em(40);
            height: em(40);
            fill: inherit;
        }

        &--facebook {
            fill: map-get($social-colors, facebook);
        }

        &--twitter {
            fill: map-get($social-colors, twitter);
        }

        &--instagram {
            fill: map-get($social-colors, instagram);
        }

        &--youtube {
            fill: map-get($social-colors, youtube);
        }

        &--vimeo {
            fill: map-get($social-colors, vimeo);
        }

        &--xing {
            fill: map-get($social-colors, xing);
        }

        &--linkedin {
            fill: map-get($social-colors, linkedin);
        }

        &:last-of-type {
            margin-right: 0;
        }
     }

     &__label {
         @include visually-hidden;
     }
// Speaker Variation for Speaker pages
     &--speaker {
         list-style: none;
         display: block;
         width: 100%;

         @include breakpoint(small only) {
             margin-top: $paragraph-margin-bottom * 2;
         }


        .c-sociallinks__li {
            display: inline-block;
            margin-right: $paragraph-margin-bottom / 2;

            @include breakpoint(large) {
                display: block;
                margin-right: 0;
            }
         }

         .c-sociallinks__item {
             display: inline-block;
             margin-bottom: $typogrid;
         }
     }
 }
