$standard-max-with: rem(205);
$premium-max-with: rem(300);
.c-speakers {
    margin-bottom: $typogrid * 5;

    &__item {
        &__mask {
            position: relative;
            transition: transform 0.2s;
            .c-speakers--standard & {
                max-width: $standard-max-with;
            }
            .c-speakers--premium & {
                max-width: $premium-max-with;
            }
        }

        &__link {
            display: block;
            margin: 0 auto $typogrid * 2 auto;
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#2ecdd5+0,2ecdd5+50,f58220+51,f58220+100 */
            background: #2ecdd5; /* Old browsers */
            background: -moz-linear-gradient(
                -45deg,
                #2ecdd5 0%,
                #2ecdd5 50%,
                #f58220 51%,
                #f58220 100%
            ); /* FF3.6-15 */
            background: -webkit-linear-gradient(
                -45deg,
                #2ecdd5 0%,
                #2ecdd5 50%,
                #f58220 51%,
                #f58220 100%
            ); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                135deg,
                #2ecdd5 0%,
                #2ecdd5 50%,
                #f58220 51%,
                #f58220 100%
            ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2ecdd5', endColorstr='#f58220',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
            &:hover {
                .c-speakers__item__mask {
                    transform: scale(0.8);
                }
            }
            .c-speakers--standard & {
                max-width: $standard-max-with;
            }
            .c-speakers--premium & {
                max-width: $premium-max-with;
            }
        }

        &__figure {
            margin: 0;
        }

        &__content {
            padding: $typogrid;
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: 2;
            left: 0;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #000;
                width: 100%;
                height: 100%;
                opacity: 0.4;
                z-index: -1;
            }
        }

        &__title {
            font-size: rem(16);
            line-height: 1;
            margin-top: 0;
            margin-bottom: rem(10);
            color: #fff;
            .c-speakers--standard & {
                font-size: rem(14);
            }
            @include breakpoint(medium) {
                font-size: rem(20);
                line-height: 1.2;
                .c-speakers--standard & {
                    font-size: rem(16);
                }
            }
        }

        &__meta,
        &__name {
            color: #fff;
            font-size: rem(13);
            line-height: 1;
            font-family: $header-font-family;
        }

        &__name {
            display: block;
            // show standard speaker name only at medium up
            .c-speakers__item--standard & {
                display: none;
                @include breakpoint(medium) {
                    display: inline-block;
                }
            }
        }
    }
}
