/* ==========================================================================
   Company Dashboard stat
   ========================================================================== */


.c-company-statistics {
    background-color: lighten(#ededed, .3);
    padding: 27% $typogrid $typogrid $typogrid;
    text-align: center;
    border-radius: rem(5);
    margin-bottom: $typogrid;
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: center $typogrid;
    background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Combined-Shape" transform="matrix(4.91559,0,0,4.91559,5.75966,5.75966)"><path d="M2.455,18C1.099,18 0,16.943 0,15.639L0,4.229C0,2.926 1.099,1.869 2.455,1.869L4.091,1.869L4.091,0.787C4.091,0.352 4.457,0 4.909,0C5.361,0 5.727,0.352 5.727,0.787L5.727,1.869L12.273,1.869L12.273,0.787C12.273,0.352 12.639,0 13.091,0C13.543,0 13.909,0.352 13.909,0.787L13.909,1.869L15.545,1.869C16.901,1.869 18,2.926 18,4.229L18,15.639C18,16.943 16.901,18 15.545,18L2.455,18ZM15.545,16.332C15.763,16.332 15.971,16.376 16.124,16.229C16.277,16.081 16.364,15.755 16.364,15.545L16.364,9L1.636,9L1.636,15.545C1.636,15.98 2.003,16.332 2.455,16.332L15.545,16.332ZM2.455,4.122C2.003,4.122 1.636,4.475 1.636,4.909L1.636,7.364L16.364,7.364L16.364,4.909C16.364,4.7 16.277,4.5 16.124,4.353C15.971,4.205 15.763,4.122 15.545,4.122L13.909,4.122L13.909,5.008C13.909,5.442 13.543,5.795 13.091,5.795C12.639,5.795 12.273,5.442 12.273,5.008L12.273,4.122L5.727,4.122L5.727,5.008C5.727,5.442 5.361,5.795 4.909,5.795C4.457,5.795 4.091,5.442 4.091,5.008L4.091,4.122L2.455,4.122Z" style="fill:#F58220;"/></g></svg>');

    &__heading {
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        margin-bottom: 0.15rem;
    }

    &__number {
        font-family: $header-font-family;
        font-weight: $header-font-weight;
        font-size: rem(26);
        @extend .u-color--primary;
        @include breakpoint(medium) {
            font-size: rem(30);
        }
    }
}





