/* ==========================================================================
   Footer navigation
   ========================================================================== */

/**
 * Outer navigation
 */

.c-footer-nav {

    &__list {
        list-style: none;
        margin: rem(map-get($grid-padding-gutters, 'small'));

        // grid adjustments
        &.grid-x {
            justify-content: center;
        }

        @include breakpoint(medium) {
            margin: 0;

            &.grid-x {
                justify-content: flex-end;
            }
        }

    }

    &__link {
        color: $white;
        font-size: $paragraph-font-size * .9;
        display: block;
        padding: rem(5);

        &:hover {
            color: $body-font-color;
        }
        &:focus {
            text-decoration: underline;
            outline: none;
        }
    }

}
