/* ==========================================================================
   full-width-image
   ========================================================================== */

/**
 * base styles
 */

body {
    overflow-x: hidden; //todo mn
}

// ToDO document where this is used...
.c-full-width-image {

    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);

}

// Classes for content blocks field

.c-image {
    --aspect-ratio: 16/9;
    &--full-width{
        img {
            width: 100%;
            height: auto;
            aspect-ratio: var(--aspect-ratio);
        }
    }

    &--full-width-column-container {

        @include breakpoint(large) {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    &--full-width-column {
        margin-left: rem(- map-get($grid-column-gutter, large)) * .5;
        margin-right: rem(- map-get($grid-column-gutter, large)) * .5;

        @include breakpoint(large) {
            position: absolute;
            top: 0;
            bottom: 0;
            overflow: hidden;
            margin: auto;
            img {
                min-width: 100%;
                max-width: none;
                min-height: 100%;
                width: auto;
                height: auto;
            }
        }
    }
}

.cell-1 .c-image--full-width-column {
    @include breakpoint(large) {
        right: 0;
    }
}
