/* ==========================================================================
hero
========================================================================== */

/**
* hero
* Hero Header ("Bühne")
*/

.c-hero {
    background-color: $primary-color;
    position: relative;
    z-index: 1;

    &__background {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        //background: get-color(primary);

        &:after,
        .c-hero__background-layer {
            background: linear-gradient(20deg, #f5b700 5%, #ec721e 40%);
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }
        &--landingpage {
            &:after {
                display: none;
            }
        }
    }

    &__headline {
        font-size: rem(map-deep-get($header-styles, small, h4, font-size));
        line-height: 1.2;

        @include breakpoint(large) {
            font-size: rem(map-deep-get($header-styles, medium, h2, font-size));
            margin-bottom: $paragraph-margin-bottom * 2;
            max-width: 80%; // force a wrap in order to give room for the header modal
        }
    }

    &__background-image {
        height: 102%;
        height: calc(100% + 20px);
        left: -1%;
        left: calc(0% - 10px);
        max-width: 102%;
        max-width: calc(100% + 20px);
        object-fit: cover;
        object-position: top center;
        opacity: .3;
        position: relative;
        top: -1%;
        top: calc(0% - 10px);
        width: 102%;

        // filtering and backup for IE/Edge:
        width: calc(100% + 20px);

        @supports (mix-blend-mode:overlay) {
            mix-blend-mode: overlay;
            opacity: 0.7;
            filter: grayscale(1);
            // apple hack https: //stackoverflow.com/questions/70891365/css-mix-blend-mode-difference-issue-in-safari
            transform: translate3d(0, 0, 0);
        }
    }

    &__content {
        margin-left: auto;
        margin-right: auto;
        max-width: $header-width;
        min-height: rem(200); // when only one headline item exists in header
        position: relative;
        z-index: 2;

        @include breakpoint(large) {
            min-height: rem(600);
            padding-left: $header-side-margin;
            padding-right: $header-side-margin;
            padding-top: rem(136);
        }
    }

    &--extended-header {
        .c-hero__content {
            min-height: rem(200); // when only one headline item exists in header
            height: 100%;
            align-items: center;
            display: flex;
            > .grid-container {
              width: 100%;
            }
            @include breakpoint(large) {
                min-height: rem(750);
                padding-top: rem(150);
                padding-bottom: 14rem;
            }
        }
    }
}
