.c-gallery {
    position: relative; // needed for preloader
    min-height: rem(100);
}

ul.gallery {
  display: flex;
  flex-wrap: wrap;
  //margin: 0;
  margin: rem(-9);
  padding: 0;
  list-style: none;

  li {
    display: block;
    padding: rem(9);
    text-align: center;
  }

  a, .gallery__item {
    display: inline-block;
    padding: rem(3);
    border: 1px solid #F58220;
    outline: none;
    box-shadow: $global-shadow;
  }

  a:hover {
      border-color: #2ECDD5;
  }


 &.size-extra-large {
    li {
      width: 100%;

      @include breakpoint(medium) {
        width: 50%;
      }
    }
  }

  &.size-248x140 {
    li {
      width: 100%;

      @include breakpoint(medium) {
        width: 50%;
      }

      @include breakpoint(large) {
        width: 25%;
      }
    }
  }

  &.size-154x87 {
    li {
      width: 50%;

      @include breakpoint(medium) {
        width: 25%;
      }

      @include breakpoint(large) {
        width: 16.6%;
      }
    }
  }

  &.size-92x92 {
    li {
      width: 50%;

      @include breakpoint(medium) {
        width: 25%;
      }

      @include breakpoint(large) {
        width: 10%;
      }
    }
  }

}

/*
.modaal-gallery-item img {
    max-height: 85vh;
    width: auto !important;
    max-width: 100%;
}
*/
