/* ==========================================================================
   User Profile Form
   ========================================================================== */

/**
 * c.user-profile-form
 * This form component is used on the user profile page
 */

.c-user-profile-form {

    &__text-input, &__textarea-input {
        border: none;
        box-shadow: rem(0) rem(2) rem(10) rem(0) rgba($global-shadow-color, 0.2);
        padding: rem(10);
    }

    &__textarea-input {
        min-height: rem(300);
    }
    
    &__experience-dates {
        margin: 0 0 rem(80) 0;
        border-bottom: rem(5) solid $dark-gray;
        padding: rem(10);
    }

}
