.c-company-logo {
    position: relative;
    margin: 0 0 $global-margin;
    padding: rem(15);
    //@include logo-dimensions(125);
    width: rem(125);
    height: rem(125 * $company-logo-ratio);
    box-shadow: $global-shadow-small;
    background: #fff;

    @include breakpoint(medium) {
        width: rem(135);
        height: rem(135 * $company-logo-ratio);
    }

    &__link {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 1px;
        text-indent: -300px;
        color: #fff;
        overflow: hidden;
    }

    &__image {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }

    &__package-label, &__jobcount {
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        padding: rem(2 6);
        margin: 0;
        background: lighten(get-color(tertiary),25%);
        font-family: $header-font-family;
        font-size: rem(12);
        color: $white;
        text-align: left;
        font-weight: 700;
        opacity: 0.9;
    }

    .c-company-logo__jobcount {
        background: transparent;
        text-align: right;
    }

    &--large {
        width: rem(215);
        height: rem(215 * $company-logo-ratio);
        padding: rem(20);

        .c-company-logo__package-label, .c-company-logo__jobcount {
            font-size: rem(14);
            padding: rem(4 10);
        }
    }

    &--xlarge {
        width: rem(230);
        height: rem(120);
        padding: 0;
        box-shadow: none;

        // xlarge supports no package labels
        .c-company-logo__package-label {
            display: none !important;
        }
        .c-company-logo__image {
            padding-bottom: 0 !important;
        }
    }

    &--centered {
        margin-left: auto;
        margin-right: auto;
    }

    /* company logo with package-label */
    &[class*="c-company-logo--package-"] {
        .c-company-logo__package-label, .c-company-logo__jobcount {
            display: block;
        }

        .c-company-logo__image {
            padding-bottom: rem(19);
        }

        &.c-company-logo--large {
            .c-company-logo__image {
                padding-bottom: rem(28);
            }
        }
    }


    &--package-jobs-here {
        .c-company-logo__package-label {
            background: $color-jobshere;
        }
    }

    &--package-premium {
        .c-company-logo__package-label {
            background: get-color(primary);
        }
    }

    &--package-plus {
        .c-company-logo__package-label {
            background: get-color(secondary);
        }
    }

    &--package-basis {
        .c-company-logo__package-label {
            background: get-color(tertiary);
        }
    }

    &--package-starter {
        .c-company-logo__package-label {
            background: #a2a2a2; //lighten(get-color(tertiary),20%);
        }
    }

    &--package-online {
        .c-company-logo__package-label {
            background: #ececec;
            color: get-color(tertiary);
        }
        .c-company-logo__jobcount {
            color: get-color(tertiary);
        }
    }
}
