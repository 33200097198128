/* ==========================================================================
   modal login Tab Styles
   ========================================================================== */

/**
 * base styles
 */

$login-transition-settings: .3s ease-in-out;

.c-login-tabs {

    &__nav {
        margin: 0;
        padding: 0;
        list-style-type: none;
        list-style-position: inherit; // needed for Edge and IE
        display: flex;
        //justify-content: space-around;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }

    &__item {
        border-bottom: 1px solid $dark-gray;
        transition: border $login-transition-settings;
        flex: 1 1 auto;
        border-right: 1px solid #fff;

        &:last-child {
            border-right: none;
        }

        // item hover settings
        &:hover, &.is-active {
            color: $primary-color;

            .c-login-tabs__link {
                color: $primary-color;
            }

            .c-login-tabs__icon {
                fill: $primary-color;
            }
        }

        &.is-active {
            font-weight: 700;
            border-bottom: rem(1) solid $primary-color;
        }
    }

    &__link {
        color: $body-font-color;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: rem(12);
        padding-bottom: rem(15);
        font-size: $lead-font-size;

        &:focus {
            outline: transparent;
        }

    }

    &__text {
        display: block;
        margin: 0 rem(10);
        white-space: nowrap;
    }

    &__icon {
        fill: $body-font-color;
        width: rem(25);
        height: rem(25);
        margin: 0 rem(10);
        display: block;
        transition: fill $login-transition-settings;
    }

    &__content {
        margin-top: $paragraph-margin-bottom;
    }

    &__button-separator {
        display: block;
        margin: rem(10 0);
    }

    &__section {
        @include visually-hidden();

        &.is-active {
            @include visually-hidden-undo();
        }
    }


}
