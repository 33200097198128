/* ==========================================================================
   user-dashboard-tabs
   ========================================================================== */


.c-user-dashboard-tabs {


/**
 * Accordion Styling
 * Foundation Accordion is implemented for small and medium screens. Foundation Accordion CSS is used here.
 */  

@include breakpoint(medium down) {

  .accordion-title {
      font-weight: 700;
      padding-right: rem(20);
      // background settings
      box-shadow: rem(0) rem(2) rem(8) $global-shadow-color;
      background-repeat: no-repeat;
      background-size: rem(15) rem(15);
      background-position: 98% center;
      background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="Capa_1" transform="matrix(2.24763e-16,0.793439,-0.793439,2.24763e-16,107.921,-35.6916)"><path d="M139.179,73C139.179,72.143 138.75,71.179 138.107,70.536L88.179,20.607C87.536,19.964 86.571,19.536 85.714,19.536C84.857,19.536 83.893,19.964 83.25,20.607L77.893,25.964C77.25,26.607 76.821,27.571 76.821,28.429C76.821,29.286 77.25,30.25 77.893,30.893L120,73L77.893,115.107C77.25,115.75 76.821,116.714 76.821,117.571C76.821,118.536 77.25,119.393 77.893,120.036L83.25,125.393C83.893,126.036 84.857,126.464 85.714,126.464C86.571,126.464 87.536,126.036 88.179,125.393L138.107,75.464C138.75,74.821 139.179,73.857 139.179,73Z" style="fill:#8a8a8a;fill-rule:nonzero;"/></g></svg>');
  }

}


/**
 * Tabs Styling
 * not using the tab foundation css, the tab function starts however via javascript starting at the large breakpoint.
 */

  @include breakpoint(large) { 

      &__list {
        display: flex;
        justify-content: flex-start;
        border: none;
        list-style-type: none;
        list-style-position: inherit; // needed for Edge and IE
        margin-left: 0;

        // background settings
        box-shadow: rem(0) rem(2) rem(8) $global-shadow-color;
        padding: $paragraph-margin-bottom * .7 $paragraph-margin-bottom * .5;
      }

      &__link {
        color: $black; //todo mn $tertiary-color
        font-size: $lead-font-size;
        font-weight: 700;
        color: map-get($foundation-palette,'tertiary');

        &:focus {
          outline: none;
        }
      }

      &__item {

        flex: 1 1 auto;

        &.is-active {

            .c-user-dashboard-tabs__link {
              color: $primary-color;
              font-size: $lead-font-size-large;
            }
        }

      }

      // Here we need to hide and show the content, based on the classes that Foundation writes via JavaScript

      .tabs-panel {
          @include visually-hidden();

          &.is-active {
              @include visually-hidden-undo();
          }
      }

  }

}