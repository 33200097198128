/* ==========================================================================
   Header meta nav layout
   ========================================================================== */

/**
 * base styles
 */

.l-page-meta-nav {
    position: absolute;
    width: 100%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    max-width: $header-width;

    @include breakpoint(large) {
        padding-left: $header-side-margin;
        padding-right: $header-side-margin;
    }

}