/* ==========================================================================
   image-row
   ========================================================================== */

/**
 * base styles
 */

.c-icon-box {
    display: flex;
    //flex-direction: column; // issue #62
    justify-content: space-between; // issue #62
    flex-wrap: wrap; // issue #62
    align-items: center;
    margin-bottom: $global-margin;

    &__heading {
        $min_width: 320px;
        $max_width: 1200px;
        $min_font: 28px;
        $max_font: 60px;
        @include fluid-type($min_width, $max_width, $min_font, $max_font);
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: rem(10);
        font-weight: 700;
        text-align: center;
    }

    &__icon {
        width: rem(120);
        height: rem(120);
    }

    &__subline {
        line-height: 1.2;
        margin-top: 0;
        margin-bottom: rem(10);
        text-align: center;
        font-size: $lead-font-size-small;

        @include breakpoint(medium) {
            font-size: $lead-font-size;
        }

    }

    &__header {
        @include breakpoint(medium) {
            text-align: center;
        }
    }

    &__arrow {
        flex: 0 1 auto;
        width: rem(15);
        height: rem(15);
        //margin-right: rem(map-get($grid-column-gutter, large)) * .5;
        fill: $black;

        @include breakpoint(medium) {
            display: none;
        }
    }

}


/* Variations
   ========================================================================== */

.c-icon-box {

    &--content {

        margin-left: rem(- map-get($grid-column-gutter, large)) * .5;
        margin-right: rem(- map-get($grid-column-gutter, large)) * .5; // remove extra left right padding from grid
        border-bottom: rem(1) solid $medium-gray;
        padding: rem(map-get($grid-column-gutter, large) * .5);
        
    
        @include breakpoint(medium) {
            margin-left: 0;
            margin-right: 0;
            padding: 0;
            border-bottom: none;
        }

        .c-icon-box__header {
            display: flex;
            align-items: center;
            width: 100%;
            flex: 0 1 auto;

            @include breakpoint(medium) {
                display: block;
            }
        }


        .c-icon-box__content {
            margin: 0;
            padding: rem(15 0 0 10);
            max-height: rem(1);
            overflow: hidden;
            transition: max-height 1s ease-out;
            flex: 0 1 auto;

            @include breakpoint(medium) {
                padding: 0;
                text-align: center;
                max-height: 100%;
            }
        }


        .c-icon-box__heading {
            //font-size: rem(map-deep-get($header-styles, small, h5, font-size));
            font-size: $paragraph-font-size;
            text-align: left;
            flex: 0 1 80%;
            margin-bottom: 0;

            @include breakpoint(medium) {
                text-align: center;
                padding: 0 ;
                font-size: rem($paragraph-font-size * 1.2);
            }
        }

        .c-icon-box__subline {
            font-size: $paragraph-font-size;
        }

        .c-icon-box__icon {
            fill: $primary-color;
            width: rem(60);
            height: rem(60);
            margin-right: rem(15);
            flex: 0 0 20%;
            
            @include breakpoint(medium) {
                width: rem(80);
                height: rem(80);
                margin-right: 0;
                margin-bottom: $paragraph-margin-bottom;
            }
        }

    }

}