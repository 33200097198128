@charset "UTF-8";
// http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
    width: device-width;
}
@import 'components/easymde';

// import modnern-normalize (https://github.com/sindresorhus/modern-normalize)
@import '../node_modules/modern-normalize/modern-normalize';

// import foundation settins and scss
@import '../node_modules/foundation-sites/scss/util/util';
@import 'functions';
@import 'settings';
@import '../node_modules/foundation-sites/scss/foundation';

// import 3rd party components
@import '../node_modules/@glidejs/glide/src/assets/sass/glide.core.scss';
@import '../node_modules/filepond/dist/filepond';

// Foundation Feature Includes
@include foundation-global-styles;
@include foundation-xy-grid-classes(
    // include only those you need...
    $base-grid: true,
    $margin-grid: true,
    $padding-grid: true,
    $block-grid: true,
    $collapse: false,
    $offset: true,
    $vertical-grid: false,
    $frame-grid: false
);
@include foundation-typography;
@include foundation-forms;
//@include foundation-button;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;

// custom mixins
@import 'mixins';

// Local Imports
@import 'lazyload';
@import 'layout';
@import 'typography';
@import 'animations';

// Components
@import 'components/accordion';
@import 'components/cust-accordion';
@import 'components/backlink';
@import 'components/buttons';
@import 'components/card';
@import 'components/company-logo';
@import 'components/company-status';
@import 'components/compare-box';
@import 'components/download-card';
@import 'components/footer-nav';
@import 'components/form';
@import 'components/form-reg-cv';
@import 'components/full-width-image';
@import 'components/hero';
@import 'components/icon-box';
@import 'components/icon-wallpaper';
@import 'components/extended-header';
@import 'components/job-highlights';
@import 'components/jobblock';
@import 'components/jobs-filter';
@import 'components/jobs-filtercriteria';
@import 'components/lead';
@import 'components/link';
@import 'components/logo';
@import 'components/map';
@import 'components/meta-nav';
@import 'components/mobile-nav';
@import 'components/modal-login';
@import 'components/nav';
@import 'components/notify';
@import 'components/pagination';
@import 'components/pricing-table';
@import 'components/slider';
@import 'components/social-icons';
@import 'components/steps';
@import 'components/testimonial';
@import 'components/title';
@import 'components/modal';
@import 'components/preloader';
@import 'components/feedback';
@import 'components/sharing';
@import 'components/sociallinks';
@import 'components/dynamic-input';
@import 'components/datepicker.scss';
@import 'components/media-upload.scss';
@import 'components/edit-jobs-list.scss';
@import 'components/contactselect';
@import 'components/gallery';
@import 'components/speakers';
@import 'components/timetable';
@import 'components/timetable-tabs';
@import 'components/readmore';
@import 'components/logoline';
@import 'components/benefits';
@import 'components/youtubevideo';
@import 'components/job-header';
@import 'components/workshop-dashboard';
@import 'components/company-dashboard-stat';
@import 'components/user-profile-progress';
@import 'components/user-dashboard-search-criteria';
@import 'components/user-dashboard-tabs';
@import 'components/user-dashboard-hero';
@import 'components/user-profile-multiform';
@import 'components/user-dashboard-stat';
@import 'components/user-profile-form';
@import 'components/user-statistic-card';

//Layouts
@import 'layouts/magazine-grid';
@import 'layouts/page-header';
@import 'layouts/page-footer';
@import 'layouts/page-meta-nav';
@import 'layouts/breadcrumb';
@import 'layouts/extended-header';

// Overrides - should be loaded last
@import 'print';
@import 'utils';
