.c-benefits {

    &__list {
        list-style: none;
    }

    &__item {
        padding: rem(17);
        margin-bottom: $typogrid*2;
        border: rem(1) solid $light-gray;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    &__item__icon {
        width: rem(30);
        height: rem(30);
        fill: get-color(primary);
        flex-shrink: 0;
        align-self: center;
        flex-basis: rem(30);
        margin-right: rem(10);
    }

    &__item__name {
        font-size: $paragraph-font-size;
        align-self: auto;
        flex-basis: 80%;
        flex-grow: 1;
        align-self: center;
    }
    &--aside {
        .c-benefits__list {
            margin-left: 0;
        }
        .c-benefits__item {
            padding: rem(1);
            margin-bottom: $typogrid;
            border: none;
        }
        .c-benefits__item__icon {
            flex-basis: rem(25);
        }
        .c-benefits__item__name {
            flex-basis: 90%;
        }
    }
}
