/* ==========================================================================
   link
   ========================================================================== */

/**
 * c-link
 * Links with special features, such as an svg icon
 */

 .c-link {
     display: block;
        transition: color .3s ease-in-out;

    &__icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        transition: fill .3s ease-in-out;
        transform: translateY(-1px);
        margin-right: rem(4);
    }

    &:hover, &:focus {
        color: $primary-color;
        .c-link__icon {
            fill: $primary-color;
        }
    }

}



/* Variations
========================================================================== */

/**
* colors
*/

.c-link--primary {

    color: $primary-color;

    .c-link__icon {
        fill: $primary-color;
    }

}

.c-link--secondary{

    color: $secondary-color;

    .c-link__icon {
        fill: $secondary-color;
    }

}

.c-link--tertiary {

    color: map-get($foundation-palette, tertiary);

    .c-link__icon {
        fill: map-get($foundation-palette, tertiary);
    }

    &:hover, &:focus {
        color: $primary-color;

        .c-link__icon {
            fill: $primary-color;
        }
    }

}

.c-link--white{

    color: $white;

    .c-link__icon {
        fill: $white;
    }

    &:hover, &:focus {
        color: $body-font-color;

        .c-link__icon {
            fill: $body-font-color;
        }
    }

}

.c-link--white--transparent{

    color: rgba($white,.8);

    .c-link__icon {
        fill: rgba($white,.8);
    }

    &:hover, &:focus {
        color: $white;

        .c-link__icon {
            fill: $white;
        }
    }
}
