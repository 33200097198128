/* ==========================================================================
   #UTILITY STYLES
   ========================================================================== */
$utilprefixclass: '' !default; // required for editor.css
/**
 * Utility Styles
 *
 * Used to add the following styles:
 *
 * 1. margin styles
 * 2. padding styles
 * 3. js, no-js
 * 4. text color
 * 5. background color
 * 6. box shadow
 * 7. visually hidden
 * 8. max height transition
 * 9. form input effects
 * 10. Floating Labels from Foundation
 * 11. custom css animations
 */

.#{$utilprefixclass}u-no-margin {
    margin: 0 !important;
}
.#{$utilprefixclass}u-no-margin--top {
    margin-top: 0 !important;
}
.#{$utilprefixclass}u-no-margin--bottom {
    margin-bottom: 0 !important;
}

.#{$utilprefixclass}u-no-padding {
    padding: 0 !important;
}
.#{$utilprefixclass}u-no-padding--top {
    padding-top: 0 !important;
}
.#{$utilprefixclass}u-no-padding--bottom {
    padding-bottom: 0 !important;
}

.#{$utilprefixclass}u-pos-relative {
    position: relative;
}

.#{$utilprefixclass}u-extra-margin--right {
    margin-right: $paragraph-margin-bottom;
}

.#{$utilprefixclass}u-extra-margin--left{
    margin-left: $paragraph-margin-bottom;
}

@each $name, $size in $extra-class-sizes {
    .#{$utilprefixclass}u-extra-#{$name}-margin--top {
        margin-top: $size !important;
    }
    .#{$utilprefixclass}u-extra-#{$name}-margin--bottom {
        margin-bottom: $size !important;
    }
    .#{$utilprefixclass}u-extra-#{$name}-padding--top {
        padding-top: $size !important;
    }
    .#{$utilprefixclass}u-extra-#{$name}-padding--bottom {
        padding-bottom: $size !important;
    }
}

.u-no-shadow {
    box-shadow: none !important;
}

html.no-js {
    .u-show-with-js {
        display: none !important;
    }
}
html.js {
    .u-hide-with-js {
        display: none !important;
    }
}

@each $name, $color in map-merge($foundation-palette, (white: #fff)) {
    .#{$utilprefixclass}u-color--#{$name} {
        color: $color !important;
        fill: $color !important;
    }
}

@each $name, $color in map-merge($foundation-palette, (light: rgba-to-rgb(rgba(get-color(secondary),0.1)))) {
    .#{$utilprefixclass}u-background--#{$name} {
        background: $color; //rgba($color, $global-transparency);

        > *, > div > * {
            color: color-pick-contrast($color, ($white, $body-font-color), 1.9);
            fill: color-pick-contrast($color, ($white, $body-font-color), 1.9);
        }
    }
}

/* note: this already is available in Foundation as '.show-for-sr' (show for screenreaders). see @include foundation-visibility-classes */
.visually-hidden {
    @include visually-hidden();
}

.visually-hidden-undo {
    @include visually-hidden-undo();
}

.u-font-weight--400 {
    font-weight: 400;
}

.u-label-block {
    display: block;
    position: relative;
    margin-top: rem(10);
}

.u-label-flex {
    display: flex;
    position: relative;
    margin-top: rem(10);
}


//-------------- input switch effect

.u-switch {
    position: relative;
    display: inline-block;
    width: rem(40);
    height: rem(22);


    input {
        display: none; // hide original checkbox

        &:checked + .u-switch__slider{
            background-color: $light-gray;

            &:before {
                transform: translateX(rem(15));
            }
        }

        &:focus + .u-switch__slider{
            box-shadow: 0 0 rem(1) $primary-color;
        }
    }

}


/* The input slider */
.u-switch__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $light-gray;
    transition: .4s;
    border-radius: rem(34);

    &:before {
        position: absolute;
        content: '';
        height: rem(20);
        width: rem(20);
        left: rem(2);
        bottom: rem(1);
        background-color: $secondary-color;
        transition: .4s;
        border-radius: 50%;
    }


}




/* @todo remove and use padding/margin from above. see $extra-class-sizes */
// classes for layout and component spacing
/*.u-layout-spacing {
    margin-bottom: $global-margin * 2;
}*/

// custom css animations

.u-fade-in {
    visibility: visible;
    opacity:1;
    animation: fadein .5s ease-in-out;
}


.#{$utilprefixclass}u-error {
    color: get-color(alert);
    border: rem(2) solid get-color(alert);
    border-radius: $global-radius;
    padding: rem(16 20);

    &--inline {
        background-color: get-color(alert);
        color: $white;
        display: inline-block;
        width: 100%;
        padding: rem(5);
        line-height: 1;
        font-size: $small-font-size;
        margin-bottom: rem(15);
    }

    &--label-wrapper {
        input {
            border-color: get-color(alert);
            margin-bottom: 0;

            &::placeholder {
                color: get-color(alert);
            }
        }
    }

    &--label-wrapper-simple {
        input {
            border-color: get-color(alert);

            &::placeholder {
                color: get-color(alert);
            }
        }
    }
}

.#{$utilprefixclass}u-success {
    color: get-color(success);
    border: rem(2) solid get-color(success);
    border-radius: $global-radius;
    padding: rem(16 20);
}

.#{$utilprefixclass}u-warning {
    color: get-color(warning);
    border: rem(2) solid get-color(warning);
    border-radius: $global-radius;
    padding: rem(16 20);
    font-weight: $global-weight-bold;
}

input.u-progress-empty {
    color: get-color('warning');
    border-bottom: rem(2) solid get-color('warning');

    &::placeholder {
        color: get-color('warning');
    }
}

textarea.u-progress-empty {
    border: rem(2) solid get-color('warning');
}

.u-disabled {
    cursor: not-allowed !important;
    opacity: 0.9;
}

.u-background--light {

    .c-preloader-overlay {
        background-color: transparent;
    }
}

 .u-preloader-container {
     min-height: rem(100);
     position: relative; // only needed for the placement of the preloader
 }

 // usercentric overrides
 .uc-embedding-container
 {
    .uc-embedding-wrapper {
        width: 100%;
        padding: rem(20);
        box-shadow: $global-shadow;
    }

    button.uc-embedding-accept {
        background: $primary-color;
    }
 }

