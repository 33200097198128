/* ==========================================================================
   logo
   ========================================================================== */

.c-logo {
    display: block;

    &__image {
        display: block;
        width: em(180);
        height: em(42);
        fill: get-color(primary);
        transition: fill ease-in-out 0.15s;

        @include breakpoint(medium) {
            width: em(190);
            height: em(44);
        }

        //&:hover,
        //&:focus {
        //    fill: get-color(secondary);
        //}
    }

    &:focus,
    &:hover {
        outline: none;
        .c-logo__image {
            fill: get-color(secondary);
        }
    }
}
