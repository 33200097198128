/* ==========================================================================
   user-profile-progress
   ========================================================================== */

/**
 * base styles
 */

.c-user-profile-progress {

  .progress {
    padding: rem(5);
    border-radius: rem($progress-height / 2);
  }

  .progress-meter {
    border-radius: rem($progress-height / 2);
  }

  @include breakpoint(large) {
    margin-top: 0;
  }

}