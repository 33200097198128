/* ==========================================================================
   Readmore Text Accordion styling
   ========================================================================== */

/**
 * c-read-more
 * This is a description of the following block
 */

.c-read-more,
.c-read-less {

    &__content {
        display: inline;
        @include visually-hidden;

        &.is-active {
            @include visually-hidden-undo;
        }
    }

    &__button {
        color: $primary-color;
        transition: color .3s ease-in-out;
        cursor: pointer;

        &:hover {
            color: $secondary-color;
        }
    }
}

.c-read-more {
    &.is-active {
        margin-top: $global-margin;
    }
}
