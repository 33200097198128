/* ==========================================================================
   User Feedback Styles
   ========================================================================== */


   // User Feedback Responses

.c-error {
    /*background-color: lighten(map-get($foundation-palette, alert), 10%);
    color: $white;
    padding: rem(20);
    border: rem(1) solid map-get($foundation-palette, alert);*/
    text-align: center;

    &__icon {
        fill: map-get($foundation-palette, alert);
        width: rem(50);
        height: rem(50);
    }

    &__text {
        display: block;
        color: map-get($foundation-palette, alert);
    }
}

.c-success {
    /*background-color: lighten(map-get($foundation-palette, success), 10%);
    color: $white;
    padding: rem(20);
    border: rem(1) solid map-get($foundation-palette, success);*/

    &__icon {
        fill: map-get($foundation-palette, success);
        width: rem(50);
        height: rem(50);
    }

    &__text {
        display: block;
        color: map-get($foundation-palette, success);
    }
}
