/* ==========================================================================
   #ANIMATIONS
   ========================================================================== */

// fade in animation
@keyframes fadein {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to   {
    opacity: 1;
    visibility: visible; }
}

@keyframes fadeout {
  from { opacity: 1; visibility: visible; }
  to   { opacity: 0; visibility: hidden;}
}

// animate in
@keyframes moveright {
  from {
    position: relative;
    left: 0;
  }
  to   {
    position: relative;
    left: rem(10); }
}

// logo rotation
@keyframes rotateLogo {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(50deg);
  }

  30% {
    transform: rotate(200deg);
  }

  90% {
    transform: rotate(320deg);
  }
  100% {
    transform: rotate(360deg);
  }
}