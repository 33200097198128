/* ==========================================================================
   Footer layout
   ========================================================================== */

/**
 * base styles
 */

 .l-page-footer {
    padding-top: rem(map-get($grid-padding-gutters, 'medium'));
    padding-bottom: rem(map-get($grid-padding-gutters, 'medium'));
    box-shadow: inset rem(0 35 35 -35) rgba(0,0,0,0.3);
}
