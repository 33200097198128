// show href value when a tag has no text value (https://twitter.com/AllThingsSmitty/status/946381631836901377)
a[href^="http"]:empty::before {
    content: attr(href);
}

figure {
    margin: 0 0 $global-margin;

    img {
        display: block;
        margin: 0 auto;
    }
}

legend {
    padding: rem(0 4);;
    margin-left: rem(-4);
}

.is-error {
    color: get-color(alert);
    border: rem(2) solid get-color(alert);
    padding: rem(16 32);
    font-weight: $global-weight-bold;
}

.is-success {
    color: get-color(success);
    border: rem(2) solid get-color(success);
    padding: rem(16 32);
    font-weight: $global-weight-bold;
}

// overrides styles for h1-6 to be used as simple bold text
.paragraph-heading {
    font-size: rem(16);
    margin-bottom: rem(8);
    font-weight: $global-weight-bold;
}
