/* ==========================================================================
   Testimonial Card
   ========================================================================== */

/**
 * base styles
 */

 .c-testimonial {
     position: relative;
     max-width: rem(1060);
     margin: 0 auto;

     blockquote p {
         margin-top: 0; // blockquote overrides
     }

     &__quote {
        // blockquote overrides
        padding: rem(32);
        color: $body-font-color;
        border-left: none;
        background-color: transparent;

        &::before, &::after {
            content: '';
        }


     }

     &__media {
        // wrapper around images
        display: flex;
        justify-content: space-between;
        margin-bottom: $paragraph-margin-bottom;
     }

     &__logo {
        position: relative;
        right: -5%;
        top: rem(7);
        z-index: 5;

        img {
            box-shadow: rem(0) rem(5) rem(15) rem(0) $global-shadow-color;
        }
     }

     &__image {
        margin: 0;
        position: relative;
        top: rem(20);

     }

     &__quote-text {
        color: $body-font-color;
        font-weight: 400;
        font-size: $lead-font-size-small;
        line-height: 1.4;
        margin-bottom: $paragraph-margin-bottom;
        margin-top: rem(20);
        white-space: normal; //issue 60

        @include breakpoint(medium) {
            font-size: $lead-font-size-medium;
        }
     }

     &__author {
         display: block;
         font-weight: 700;
         line-height: 1.2rem;
         font-size: inherit;
         font-style: normal;
         color: $body-font-color;
         // generic site style overrides
         &::before{
             content: '';
         }
     }

     &__title {
         display: block;
         font-size: $small-font-size;
         margin-bottom: $paragraph-margin-bottom;
     }
 }
