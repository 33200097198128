/* ==========================================================================
   Dynamic Input Items - on the Profile edit page
   ========================================================================== */

/**
 * c-dynamic-input
 * User can dynamically add and delete input fields 
 */

.c-dynamic-input {

    &__wrapper {
        display: flex;
        align-items: center;
    }
   
    &__btn {
        padding: rem(10);
        color: $white;
        margin-bottom: $global-margin;
        flex: 0 1 auto;

        &:hover {
            .c-dynamic-input__icon {
                fill: $secondary-color;
            }
        }
    }

    &__icon {
        width: rem(25);
        height: rem(25);
        fill: $dark-gray;
        transition: fill 0.3s ease-in-out;
    }

    input {
        flex: 1 0 85%;
    }
}
