/* ==========================================================================
   Pagination
   ========================================================================== */



.c-pagination {
    margin: rem(0 0 10 0);
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;


    &-page, &-prev, &-next {
        margin: rem(0 5);
        flex: 0 1 auto;

        a {
            display: block;
            padding: rem(2 8);
            font-size: $lead-font-size;
            color: $dark-gray;

            &:hover {
                color: $primary-color;

                .c-pagination__icon {
                    fill: $primary-color;
                }
            }
        }

        &.current {
            color: $primary-color;
            padding: rem(2 8);
            font-size: $lead-font-size;

            a {
                color: $primary-color;
            }
        }
    }

    &-prev a, &-next a {
        font-size: $paragraph-font-size;
    }

    &__icon {
        width: rem(20);
        height: rem(20);
        fill: $dark-gray;
        position: relative;
        top: rem(2);

    }

    &__icon--prev {
        transform: scaleX(-1);
    }

}
