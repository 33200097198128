/* ==========================================================================
   Custom Accordion
   ========================================================================== */

/**
 * c-cust-accordion
 * Init for the custaccordion js 
 */

.c-cust-accordion {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease-out; // open, close effect
}

.has-max-height {
    max-height: 80em !important;
    transition: max-height 1s ease-in-out;
}