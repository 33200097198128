.c-company-status {
    position: absolute;
    top: 0;
    right: 0;

    @include breakpoint(large) {
        top: rem(20);
        right: rem(20);
    }
}
