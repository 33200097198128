/* ==========================================================================
   User Profile Multiform
   ========================================================================== */

/**
 * c.user-profile-multiform
 * This form component is used on the user profile page
 */

.c-user-profile-multiform {
    
    &__text-input, &__textarea-input {
        border: none;
        box-shadow: rem(0) rem(2) rem(10) rem(0) rgba($global-shadow-color, 0.2);
    }

    &__icon {
        width: rem(30);
        height: rem(30);
        margin-right: rem(10);
        fill: map-get($foundation-palette, 'lightgray' );
        transition: fill .3s ease-in-out;
    }

    &__addfield {
        display: flex;
        align-items: center;
        color: map-get($foundation-palette, 'lightgray' );
        cursor: pointer;
        transition: color .3s ease-in-out;

        &:hover {
            color: $primary-color;

            .c-user-profile-multiform__icon {
                fill: $primary-color;
            }
        }
    }

}
