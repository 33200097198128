/* ==========================================================================
   Breadcrumb
   ========================================================================== */

/**
 * l-breadcrumb
 * styles the breadcrumb layout area
 */

.l-breadcrumb {
    background: linear-gradient(149deg, #f5b700 10%, #ec721e 75%);
    color: $white;
    padding: rem(10) 0;
}
