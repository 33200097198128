/* ==========================================================================
   steps
   ========================================================================== */

/**
 * steps
 */

.c-steps {
  $c-steps_small: 20%;
  $c-steps_large: 40%;

  &__item {
    margin-bottom: rem(60);
  }

  &__item .grid-x .cell:nth-child(1) {
    font-family: $title-font-family;

    @include breakpoint(medium) {
      width: $c-steps_small;
    }

  }
  &__item .grid-x .cell:nth-child(2),
  &__item .grid-x .cell:nth-child(3) {

    @include breakpoint(medium) {
      width: $c-steps_large;
    }

  }

  &__item .grid-x .cell:nth-child(2) {

    @include breakpoint(medium) {
      padding-right: rem(20);
    }

  }


  &__item:nth-child(2n) .grid-x .cell:nth-child(1) {
    order: unset;

    @include breakpoint(medium) {
      order: 2;
      width: $c-steps_small;
    }

  }
  &__item:nth-child(2n) .grid-x .cell:nth-child(2) {
    order: unset;

    @include breakpoint(medium) {
      order: 3;
      width: $c-steps_large;
      padding-right: rem(20);
    }

  }
  &__item:nth-child(2n) .grid-x .cell:nth-child(3) {
    order: unset;

    @include breakpoint(medium) {
      order: 1;
      width: $c-steps_large;
    }

  }


  &__media {
    margin: 0;
  }
  &__media img {
    width: 100%;
  }

  &__number {
    font-size: rem(70);
    color: $primary-color;

    @include breakpoint(large) {
      font-size: rem(100);
    }

  }

}
