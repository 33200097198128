/* ==========================================================================
   Meta navigation
   ========================================================================== */

/**
 * meta navigation is over the main navigation
 *
 */

.c-meta-nav {
    position: relative;
    z-index: 2;

    &__list {
        margin: rem(0 -10 0 0);
        padding: 0;
        list-style: none;
    }

    &__item {
        display: inline-block;
    }

    &__link {
        display: inline-block;
        padding: rem(12 10);
        color: $white;
        font-size: $small-font-size;
        line-height: rem(16);

        &:hover,
        &.active,
        &:focus {
            color: $white;
            text-decoration: underline;
        }
        &:focus {
            outline: none;
        }
    }
}



/* Variations
   ========================================================================== */

   // Primary-color meta navigation should be used on pages without a hero image area.
    .c-meta-nav--no-hero {

        .c-meta-nav__link {
            color: $body-font-color;

            &:hover, &.active {
                color: $primary-color;
            }
        }
    }


   .c-meta-nav--mobile-nav {
       background-color: $white;

        .c-meta-nav__link {
            font-size: $small-font-size;
            display: inline-block;
            padding: rem(3 5);
            color: $body-font-color;
        }

        .c-meta-nav__list {
            text-align: center;
        }
   }
