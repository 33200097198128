/* ==========================================================================
   Title
   ========================================================================== */

.c-title {
    display: block;
    max-width: 100%;
    margin: rem(20 0 40 0);
    transform: rotate(-4deg);

    color: $white;
    line-height: .8;
    font-family: $title-font-family;
    font-weight: $title-font-weight;
    text-transform: uppercase;
    letter-spacing: rem(-4);
    text-shadow: $hero-text-shadow;

    $min_width: 320px;
    $max_width: 1200px;
    $min_font: 36px;
    $max_font: 75px;
    @include fluid-type($min_width, $max_width, $min_font, $max_font);

    @include breakpoint(medium) {
        max-width: 80%;
        letter-spacing: rem(-5);
    }

    @include breakpoint(large) {
        max-width: 50%;
        margin: rem(40 0 30 0);
    }
}
