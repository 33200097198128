//--- hide visually ---//
@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin visually-hidden-undo {
  position: relative;
  width: inherit;
  height: inherit;
  overflow: inherit;
  clip: auto;
}


//--- fluid font sizes ---//

/* Use:

$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

*/

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

/*
 * Maintain aspect ratio mixin
 * from https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
 */
 @mixin aspect-ratio($ratio, $childSelector: "> img") {
    position: relative;
    &:before {
      display: block;
      content: "";
      width: 100%;
      padding-top: $ratio * 100%;
    }
    #{$childSelector} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
}
