/* ==========================================================================
   c-card & l-card-with-image

   note: the l-card-with-image overrides some c-card styles (yes, this is bad)
   ========================================================================== */

 $card-padding: rem(24);
 $card-overlap: rem(- map-get($grid-column-gutter, large));

.c-card {
    z-index: 1;
    position: relative;
    width: 100%;
    margin: $global-margin auto $global-margin auto;
    padding: $card-padding;
    background-color: #fff;
    box-shadow: $global-shadow;

    &--400 {
        max-width: rem(400);
    }

    // close button for modals
    &__close {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: rem(10);
        cursor: pointer;
        width: rem(40);
        height: rem(40);
        fill: $dark-gray;
        transition: fill .15s ease-in-out;

        &:hover {
            fill: $primary-color;
        }

        @include breakpoint(large) {
            display: block;
        }
    }

    // remove margin top/bottom for first/last element in the card (headlines, paragraphs etc)
    & >:first-child {
        margin-top: 0;
    }
    & >:last-child {
        margin-bottom: 0;
    }
    > .grid-background > .grid-container {
        padding: 0;
    }
    > .grid-background > .grid-container > .grid-x > .cell:first-of-type > :first-child {
        margin-top: 0;
    }
    > .grid-background > .grid-container > .grid-x > .cell:last-of-type > :last-child {
        margin-bottom: 0;
    }


    /*
     * Position variations
     */

    &--sticky-right,
    &--sticky-left,
    &--sticky-bottomright,
    &--fixed-hero,
    &--fixed-hero-center {
        @include breakpoint(medium down) {
            width: 100%;
            box-shadow: none;
            padding: 0;
        }

        @include breakpoint(large) {
            z-index: 1;
            width: rem(340);
        }
    }

    @include breakpoint(large) {
        &--sticky-right,
        &--sticky-left,
        &--sticky-bottomright {
            position: fixed;
            right: 0;
            top: 50%;
            transform: translateY(-40%);
            z-index: 10;
        }

        &--sticky-left {
            right: auto;
            left: 0;
        }

        &--sticky-bottomright {
            top: auto;
            bottom: 0;
            transform: none;
            margin-bottom: 0;
        }

        &--fixed-hero {
            width: rem(448);
            position: absolute;
            top: rem(175);
            left: 51%;
            left: calc(50% + #{map-get($grid-column-gutter, large)});
        }

        &--fixed-hero-center {
            width: rem(448);
            position: absolute;
            top: rem(210);
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--sticky-login {
        z-index: 99;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        height: 100vh;
        margin-top: 0;

        @include breakpoint(medium) {
            max-width: rem(430);
            top: 0;
            height: auto;
        }

        .c-card__close {
            display: block;
        }
    }
}

/*
 * Special: A card with a large image behind
 */

.l-card-with-image {
    //display: inline-block; // issue #57
   position: relative;

    @include breakpoint(large) {
        margin: $global-margin 0;
    }

    &__image {
        margin: 0;
        &.has-video {
            &:before {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                opacity: .4;
                background-image: svg-url('<svg width="100%" height="100%" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><g id="icon-PlayVideo" transform="matrix(1.50524,0,0,1.50524,4.70488,4.86893)"><path d="M30,0C13.449,0 0,13.449 0,30C0,46.551 13.449,60 30,60C46.551,60 60,46.551 60,30C60,13.449 46.551,0 30,0ZM30,2.857C45.008,2.857 57.143,14.992 57.143,30C57.143,45.008 45.008,57.143 30,57.143C14.992,57.143 2.857,45.008 2.857,30C2.857,14.992 14.992,2.857 30,2.857ZM20.952,19.667L20.952,40.939C20.952,42.043 21.848,42.939 22.952,42.939C23.305,42.939 23.652,42.845 23.957,42.668L42.261,32.041C43.217,31.486 43.541,30.262 42.987,29.307C42.812,29.006 42.563,28.757 42.262,28.582L23.958,17.938C23.003,17.383 21.779,17.707 21.223,18.662C21.046,18.967 20.952,19.314 20.952,19.667Z" style="fill:#fff;fill-rule:nonzero;"/></g></svg>');
                background-position: center center;
                background-repeat: no-repeat;
                background-attachment: scroll;
                background-size: 30%;
                z-index: 1;
            }
        }
    }

    .c-card {
        margin-top: 0;
        margin-bottom: 2rem; // issue #57
    }

    // positions start at medium break point
    @include breakpoint(medium) {

        /* Top Positions */
        &.l-card-with-image--top-left,
        &.l-card-with-image--top-right {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;

            .l-card-with-image__image {
                padding-top: $card-overlap*-1;
                margin-right: $card-overlap;
                flex: 0 1 100%; // issue #57
                &.has-video {
                    &:before {
                        top: $card-overlap*-1;
                        max-width: rem(650);
                        max-height: rem(361);
                    }
                }
            }

            .c-card {
                flex: 1 1 30%;
                min-width: rem(330);
                max-width: 95vw;
            }
        }

        &.l-card-with-image--top-left {
            .l-card-with-image__image {
                order: 2;
                margin-right: 0;
                margin-left: $card-overlap;
            }

            .c-card {
                order: 1;
            }
        }

        /* Bottom Positions */
        &.l-card-with-image--bottom-right,
        &.l-card-with-image--bottom-left {
            .l-card-with-image__image {
                margin-right: $card-overlap *-1;
                &.has-video {
                    &:before {
                        max-width: rem(900);
                        max-height: rem(500);
                    }
                }
            }

            .c-card {
                margin-top: $card-overlap;
                width: 75%;
                width: calc(70% + #{$card-overlap} - #{$card-padding});
                min-width: rem(330);
                float: right;
            }
        }

        &.l-card-with-image--bottom-left {
            .l-card-with-image__image {
                margin-left: $card-overlap *-1;
                margin-right: 0;
            }

            .c-card {
                float: left;
            }
        }
    }
}
