/* ==========================================================================
   magazine-grid
   ========================================================================== */

/**
 * base styles
 */

.l-magazine-grid {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none outside none;
    padding: 0;
    margin: 0;

    &__column {
        flex: 0 1 100%;

        @include breakpoint(large) {
            flex: 0 1 48.5%;
        }

    }

    &__column:nth-child(1) &__item:nth-child(1) {
        margin-top: 0;

        @include breakpoint(large) {
            margin-top: rem(112);
        }

        .u-layout-spacing {
            margin-bottom: 1.3rem; //todo mn 1rem?
        }
    }

    &__column:nth-child(2) &__item:nth-child(1) {

        .u-layout-spacing {
            margin-bottom: 1.3rem; //todo mn 1rem?
        }

    }

    &__column:nth-child(2) &__item:nth-child(2) {
        margin-right: 0;
        margin-left: 0;

        @include breakpoint(large) {
            margin-right: rem(88);
            margin-left: rem(-88);
        }
    }

    &__item {
        display: block;
        clear: both;
    }

    /// c.card settings
    & .l-card-with-image--bott-right,
    & .l-card-with-image--bott-left {

        @include breakpoint(large) {
            margin-bottom: rem(25);
        }

    }

    & .l-card-with-image--bott-right .c-card,
    & .l-card-with-image--bott-left .c-card {

        @include breakpoint(large) {
            margin-bottom: 0;
            margin-left: -2rem;
            margin-right: -2rem;
        }

    }

}
