/* ==========================================================================
   Job Highlights
   ========================================================================== */

/**
 * base styles
 */

.c-job-highlights {

    &__item {
        margin-bottom: $global-margin;
    }

    &__icon-svg-wrapper {
        display: inline-flex;
        padding: rem(5);
        background-color: #F58220;
        border-radius: 50%;
        width: rem(40);
        height: rem(40);
    }

    &__icon-svg {
        width: 100%;
        height: 100%;
        padding: rem(2);
        fill: $white;
    }

    &__text {
        padding-left: rem(20);
        font-weight: 700;
    }

}
