/* ==========================================================================
   User Dashboard User Applications List
   ========================================================================== */

/**
 * c-user-statistic card
 * Styling for the list of applications that a job seeker had submitted to comopanies
 */

 .c-user-statistic-card {
    border: rem(1) solid $medium-gray;
    padding: $global-margin;
    margin-bottom: $global-margin;
    font-size: $small-font-size;
 }
