.c-timetable {
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: $typogrid*3;

    &__tr {
        vertical-align: top;
        margin-bottom: rem(20);
    }

    &__th {
        text-align: left;
        padding: rem(15 0 0 0);
        display: block;
        @include breakpoint(large) {
            display: table-cell;
            padding-right: rem(15);
            padding-bottom: rem(25);
        }
    }
    &__hour {
        padding: rem(10);
        color: $white;
        text-align: center;
        font-size: rem(20);
        width: rem(160);
    }

    &__hour-label {
        display: block;
        line-height: 1;
    }

    &__td {
        padding: rem(30 15 50 0);
        @include breakpoint(large) {
            padding-top: rem(15);
            padding-left: rem(15);
        }
    }

    &__td--event {
        display: flex;
        flex-direction: column;
        @include breakpoint(large) {
            flex-direction: row;
        }
        &-speeddating {
            @include breakpoint(large) {
                flex-direction: column;
            }
        }
    }

    &__event {
        margin-right: rem(15);
        @include breakpoint(small only) {
            flex-basis: auto;
        }
        flex-basis: 77%;
    }

    &__speaker-wrapper {
        @include breakpoint(small only) {
            flex-basis: auto;
        }
        display: flex;
        flex-basis: 23%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: flex-start;
    }

    &__speaker {
        flex-basis: rem(190);
        margin-bottom: $typogrid*2;

        // Variations on the speaker item content
        .c-speakers__item__content {
            position: relative;
            &:before {
                background: none;
            }

        }

        .c-speakers__item__link {
            margin: 0;
        }
        .c-speakers__item__name{
            color: $body-font-color;
            font-weight: $header-font-weight;
            line-height: 1.4;
        }
    }

    &__headline {
        margin-top: rem(-6);
        margin-bottom: $typogrid;
    }

    &__meta {
        font-size: $small-font-size;
    }
}
